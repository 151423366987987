


import axios from "axios";
import apiService from '../services/api.service'
const API_URL = apiService.getApiUrl();
class DeliverableAcceptantService{
    getData(projectID){
        return axios.get(API_URL + "projectConsult/" + projectID,{
            projectID: projectID
        })
    }
    getDataByConsult(consult){
        return axios.get(API_URL + "projectByConsult/" + consult,{
            consult: consult
        })
    }
    getDataByAdmin(){
        return axios.get(API_URL + "projectByAdmin")
    }
    getDataEmp(projectID){
        return axios.get(API_URL + "projectConsultEmp/" + projectID,{
            projectID: projectID
        })
    }
    getAcknowledgeStatus(projectID,consult){
        return axios.get(API_URL + "acknowledgeStatus/" + projectID + consult,{
            pk: projectID + consult
        })
    }
    addData(data,projectID){
        return axios.post(API_URL + "addProjectConsult",{
            projectConsults: data,
            projectID: projectID
        });
    }
    removeData(data,projectID){
        return axios.post(API_URL + 'projectConsultRemove',{
            projectConsults: data,
            projectID: projectID
        })
    }
    consultAcknowledge(consultSignature,projectID,consult){
        return axios.post(API_URL + "consultAcknowledge",{
            consultSignature: consultSignature,
            projectID: projectID,
            consult: consult
        });
    }
    pspAcknowledge(pspSignature,projectID,consult){
        return axios.post(API_URL + "pspAcknowledge",{
            pspSignature: pspSignature,
            projectID: projectID,
            consult: consult
        });
    }
}
export default new DeliverableAcceptantService();