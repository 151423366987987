

<template>
  <section class="container">
    <div class="row">
      <div class="col-4">
        <router-link to="/user" class="btn btn-warning text-left"
          >Back to main menu</router-link
        >
      </div>
      <div class="col-6">
        <h2 class="py-2">Transfer Report</h2>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-1"></div>
      <div class="col-11">
        <!-- <form class="row mb-3"> -->
        <Form @submit="transferReport">
          <!-- <div class="row">
                <label for="name" class="col-2 col-form-label text-left">Project:</label>
                <div class="col-10">
                    <Field name="name" type="text" class="form-control"/>
                    <ErrorMessage name="name" class="error-feedback"/>
                </div> 
            </div> -->
          <div class="row" style="padding-top: 5px">
            <label for="date" class="col-2 col-form-label text-left"
              >Transfer From Date</label
            >
            <div class="col-10">
              <div class="input-group date" id="datepicker">
                <datepicker
                  id="date"
                  :calendar-button="true"
                  caledar-button-icon="fa fa-calendar"
                  class="form-control"
                  v-model="fromDate"
                ></datepicker>
              </div>
            </div>
          </div>
          <div class="row" style="padding-top: 5px">
            <label for="date" class="col-2 col-form-label text-left"
              >Transfer To Date</label
            >
            <div class="col-4">
              <div class="input-group date" id="datepicker">
                <datepicker
                  id="date"
                  :calendar-button="true"
                  caledar-button-icon="fa fa-calendar"
                  class="form-control"
                  v-model="toDate"
                ></datepicker>
              </div>
            </div>
            <div class="col-2">
              <button class="btn btn-primary text-right">Fetch</button>
            </div>
          </div>
        </Form>
        <!-- </form> -->
      </div>
    </div>
  </section>
  <section class="container-fluid">
    <div class="row mb-3">
      <div class="col-12">
        <h4 class="text-left">
          Transfer Data at {{ toLocalDate(fromDate) }} to
          {{ toLocalDate(toDate) }}:
        </h4>
        <div ref="table"></div>
      </div>
    </div>
  </section>
</template>
<script>
import { ref } from "vue";
import { Form } from "vee-validate";
import Datepicker from "vue3-datepicker";
import DataService from "../services/data.service";
// eslint-disable-next-line no-unused-vars
var dateFormatter = function (cell, formatterParams) {
  var value = cell.getValue();

  if (value) {
    // eslint-disable-next-line no-undef
    value = moment(value, "YYYY/MM/DD").format("MM/DD/YYYY");
  }

  return value;
};
var dateIsoFormatter = function (cell) {
  var value = cell.getValue();

  if (value) {
    // eslint-disable-next-line no-undef
    value = moment(value).format("MM/DD/YYYY HH:mm:ss");
  }

  return value;
};
// eslint-disable-next-line no-unused-vars
var dateTimeFormatter = function (cell, formatterParams) {
  var value = cell.getValue();

  if (value) {
    // eslint-disable-next-line no-undef
    value = moment(value, "YYYY/MM/DD HH:mm:ss").format("MM/DD/YYYY HH:mm:ss");
  }

  return value;
};
export default {
  name: "UserTransferReport",
  components: {
    Datepicker,
    Form,
  },
  data() {
    return {
      fromDate: ref(new Date()),
      toDate: ref(new Date()),
      userName: "",
      inputItems: [],
      table: null,
      iconC: "red",
      iconW: "45px",
      iconH: "45px",
      currentDate: "",
    };
  },
  mounted() {
    var self = this;
    self.userName = this.$store.state.auth.user.username;
    // eslint-disable-next-line no-undef
    var day = moment().toDate();
    // eslint-disable-next-line no-undef
    self.currentDate = moment(day).format("MM/DD/YYYY");

    //custom date formatter
    // eslint-disable-next-line no-unused-vars
    var dateFormatter = function (cell, formatterParams) {
      var value = cell.getValue();

      if (value) {
        // eslint-disable-next-line no-undef
        value = moment(value, "YYYY/MM/DD").format("MM/DD/YYYY");
      }

      return value;
    };

    //define data
    var tabledata = [];

    self.inputItems = tabledata;
    //Build Tabulator
    // eslint-disable-next-line no-undef
    const table = new Tabulator(self.$refs.table, {
      height: "311px",
      layout: "fitColumns",
      reactiveData: true, //turn on data reactivity
      data: tabledata, //load data into table
      columns: [
        { title: "Project", field: "project", sorter: "string", width: 100 },
        {
          title: "First Name",
          field: "firstname",
          sorter: "string",
          width: 100,
        },
        { title: "Last Name", field: "lastname", sorter: "string", width: 100 },
        { title: "Account", field: "bankAccount", sorter: "string" },
        { title: "Prompt Pay", field: "promptpay", sorter: "string" },
        { title: "G-Wallet", field: "gwallet", sorter: "string" },
        {
          title: "Date",
          field: "transferDate",
          sorter: "date",
          formatter: dateFormatter,
          sorterParams: {
            format: "iso",
          },
        },
        { title: "Bank Name", field: "bankName", sorter: "string" },
        // { title: "Branch Name", field: "branchName", sorter: "string" },
        // { title: "Branch Code", field: "branchCode", sorter: "string" },
        { title: "Tel./SMS", field: "tel", sorter: "string" },
        { title: "Email", field: "email", sorter: "string" },
        { title: "Amount", field: "amount", sorter: "number" },
        {
          title: "Created Date",
          field: "createdDate",
          sorter: "date",
          formatter: dateIsoFormatter,
          sorterParams: {
            format: "iso",
          },
        },
      ],
    });
    self.table = table;
  },
  methods: {
    toLocalDate(dateStr) {
      const date = new Date(dateStr);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var dt = date.getDate();

      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) month = "0" + month;
      return month + "/" + dt + "/" + year;
    },
    transferReport() {
      var self = this;
      self.inputItems = [];
      var data = {
        fromDate: self.toLocalDate(self.fromDate),
        toDate: self.toLocalDate(self.toDate),
        createdBy: self.userName,
      };
      DataService.transferReport(data).then((response) => {
        var result = response.data;
        result.forEach(element => {
          element.transferDate = new Date(element.transferDate)
          element.createdDate = new Date(element.createdDate)
        });
        self.inputItems = result;
      });
    },
    bindTable(d) {
      var self = this;
      //define data
      var tabledata = d;

      self.inputItems = tabledata;
      //Build Tabulator
      // eslint-disable-next-line no-undef
      // var table = new Tabulator("#example-table", {
      // eslint-disable-next-line no-undef
      self.table = new Tabulator(self.$refs.table, {
        height: "311px",
        layout: "fitColumns",
        reactiveData: true, //turn on data reactivity
        data: tabledata, //load data into table
        columns: [
          { title: "Project", field: "project", sorter: "string", width: 100 },
          {
            title: "First Name",
            field: "firstname",
            sorter: "string",
            width: 100,
          },
          {
            title: "Last Name",
            field: "lastname",
            sorter: "string",
            width: 100,
          },
          { title: "Account", field: "bankAccount", sorter: "string" },
        { title: "Prompt Pay", field: "promptpay", sorter: "string" },
        { title: "G-Wallet", field: "gwallet", sorter: "string" },
          {
            title: "Date",
            field: "transferDate",
            sorter: "date",
            formatter: dateFormatter,

            sorterParams: {
              format: "iso",
            },
          },
          { title: "Bank Name", field: "bankName", sorter: "string" },
          // { title: "Branch Name", field: "branchName", sorter: "string" },
          // { title: "Branch Code", field: "branchCode", sorter: "string" },
        { title: "Tel./SMS", field: "tel", sorter: "string" },
        { title: "Email", field: "email", sorter: "string" },
          { title: "Amount", field: "amount", sorter: "number" },
          {
            title: "Created Date",
            field: "createdDate",
            sorter: "date",
            formatter: dateIsoFormatter,
            sorterParams: {
              format: "iso",
            },
          },
        ],
      });
    },
  },
  watch: {
    inputItems: {
      handler: function (newData) {
        this.bindTable(newData);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.input-group-append {
  cursor: pointer;
}
.container {
  margin-top: 60px;
}
</style>