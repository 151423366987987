

<template>
  <section class="container">
    <div class="row">
      <div class="col-12 col-md-4">
        <router-link to="/user" class="btn btn-warning text-left"
          ><font-awesome-icon icon="circle-left"></font-awesome-icon> Back to main menu</router-link
        >
      </div>
      <div class="col-12 col-md-6">
        <h2 class="py-2">SOW List</h2>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-1"></div>
      <div class="col-11">
      </div>
    </div>
  </section>
  <section class="container-fluid">
    <div class="row mb-3">
      <div class="col-12">
        <h4 class="text-left">
          Wait to Acknowledge
        </h4>
        <div ref="tableAcknowledge"></div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <h4 class="text-left">
          Wait to PSP Approve
        </h4>
        <div ref="tablePspApprove"></div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <h4 class="text-left">
            Completed
        </h4>
        <div ref="tableCompleted"></div>
      </div>
    </div>
  </section>
</template>
<script>
import ProjectConsultService from "../services/project.consult.service";
// eslint-disable-next-line no-unused-vars
var dateFormatter = function (cell, formatterParams) {
  var value = cell.getValue();

  if (value) {
    // eslint-disable-next-line no-undef
    value = moment(value, "YYYY/MM/DD").format("MM/DD/YYYY");
  }

  return value;
};
var dateIsoFormatter = function (cell) {
  var value = cell.getValue();

  if (value) {
    // eslint-disable-next-line no-undef
    value = moment(value).format("MM/DD/YYYY HH:mm:ss");
  }

  return value;
};
// eslint-disable-next-line no-unused-vars
var dateTimeFormatter = function (cell, formatterParams) {
  var value = cell.getValue();

  if (value) {
    // eslint-disable-next-line no-undef
    value = moment(value, "YYYY/MM/DD HH:mm:ss").format("MM/DD/YYYY HH:mm:ss");
  }

  return value;
};
var acknowledgeIcon = function () {
  return '<button class="btn btn-primary">Acknowledge</button>'
};
var viewIcon = function () {
  return '<button class="btn btn-primary">View</button>'
};
export default {
  name: "SowList",
  data() {
    return {
      userName: "",
      inputItems: [],
      tableAcknowledge: null,
      tablePspApprove: null,
      tableCompleted: null,
      iconC: "red",
      iconW: "45px",
      iconH: "45px",
      currentDate: "",
    };
  },
  mounted() {
    var self = this;
    self.userName = this.$store.state.auth.user.username;
    // eslint-disable-next-line no-undef
    var day = moment().toDate();
    // eslint-disable-next-line no-undef
    self.currentDate = moment(day).format("MM/DD/YYYY");

    //custom date formatter
    // eslint-disable-next-line no-unused-vars
    var dateFormatter = function (cell, formatterParams) {
      var value = cell.getValue();

      if (value) {
        // eslint-disable-next-line no-undef
        value = moment(value, "YYYY/MM/DD").format("MM/DD/YYYY");
      }

      return value;
    };

    //define data
    var tabledata = [];

    self.inputItems = tabledata;
    //Build Tabulator
    // eslint-disable-next-line no-undef
    const tableAcknowledge = new Tabulator(self.$refs.tableAcknowledge, {
      height: "311px",
      layout: "fitColumns",
      reactiveData: true, //turn on data reactivity
      data: tabledata, //load data into table
      columns: [
        {
          formatter: acknowledgeIcon,
          width: 140,
          hozAlign: "center",
          cellClick: function (e, cell) {
            // self.$router.push('/SowForm');
            // var recid = cell.getRow().getData().recid;
            const projectName = cell.getRow().getData().projectName;
            const fullname = cell.getRow().getData().fullname;
            self.$router.push({name:'SowForm',params: {id:projectName,fullname:fullname}});
          },
        },
        { title: "Project WBS Number", field: "wbsCode", sorter: "string", width: 200 },
        {
          title: "Project Name",
          field: "projectName",
          sorter: "string",
          width: 400,
        },
        {
          title: "Acknowledge Date",
          field: "consultAckDate",
          sorter: "date",
          formatter: dateIsoFormatter,
          sorterParams: {
            format: "iso",
          },
          width:200,
        },
        {
          title: "PSP Apporve Date",
          field: "pspAckDate",
          sorter: "date",
          formatter: dateIsoFormatter,
          sorterParams: {
            format: "iso",
          },
          width:200,
        },
      ],
    });
    self.tableAcknowledge = tableAcknowledge;
    const tablePspApprove = new Tabulator(self.$refs.tablePspApprove, {
      height: "311px",
      layout: "fitColumns",
      reactiveData: true, //turn on data reactivity
      data: tabledata, //load data into table
      columns: [
        {
          formatter: viewIcon,
          width: 140,
          hozAlign: "center",
          cellClick: function (e, cell) {
            const projectName = cell.getRow().getData().projectName;
            const fullname = cell.getRow().getData().fullname;
            self.$router.push({name:'SowForm',params: {id:projectName,fullname:fullname}});
          },
        },
        { title: "Project WBS Number", field: "wbsCode", sorter: "string", width: 200 },
        {
          title: "Project Name",
          field: "projectName",
          sorter: "string",
          width: 400,
        },
        {
          title: "Acknowledge Date",
          field: "consultAckDate",
          sorter: "date",
          formatter: dateIsoFormatter,
          sorterParams: {
            format: "iso",
          },
          width:200,
        },
        {
          title: "PSP Apporve Date",
          field: "pspAckDate",
          sorter: "date",
          formatter: dateIsoFormatter,
          sorterParams: {
            format: "iso",
          },
          width:200,
        },
      ],
    });
    self.tablePspApprove = tablePspApprove;
    const tableCompleted = new Tabulator(self.$refs.tableCompleted, {
      height: "311px",
      layout: "fitColumns",
      reactiveData: true, //turn on data reactivity
      data: tabledata, //load data into table
      columns: [
        {
          formatter: viewIcon,
          width: 140,
          hozAlign: "center",
          cellClick: function (e, cell) {
            const projectName = cell.getRow().getData().projectName;
            const fullname = cell.getRow().getData().fullname;
            self.$router.push({name:'SowForm',params: {id:projectName,fullname:fullname}});
          },
        },
        { title: "Project WBS Number", field: "wbsCode", sorter: "string", width: 200 },
        {
          title: "Project Name",
          field: "projectName",
          sorter: "string",
          width: 400,
        },
        {
          title: "Acknowledge Date",
          field: "consultAckDate",
          sorter: "date",
          formatter: dateIsoFormatter,
          sorterParams: {
            format: "iso",
          },
          width:200,
        },
        {
          title: "PSP Apporve Date",
          field: "pspAckDate",
          sorter: "date",
          formatter: dateIsoFormatter,
          sorterParams: {
            format: "iso",
          },
          width:200,
        },
      ],
    });
    self.tableCompleted = tableCompleted;
    setTimeout(()=>self.getSowList(),300)
  },
  methods: {
    toLocalDate(dateStr) {
      const date = new Date(dateStr);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var dt = date.getDate();

      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) month = "0" + month;
      return month + "/" + dt + "/" + year;
    },
    getSowList() {
      var self = this;
      self.inputItems = [];
      ProjectConsultService.getDataByConsult(self.userName).then((response) => {
        var result = response.data;
        result.forEach(element => {
          if(element.consultAckDate != null)
            element.consultAckDate = new Date(element.consultAckDate)
          if(element.pspAckDate != null)
            element.pspAckDate = new Date(element.pspAckDate)
        });
        self.inputItems = result;
      });
    },
    bindTable(d) {
      var self = this;
      //define data
      var tabledata = d;

      self.inputItems = tabledata;
      //Build Tabulator
      // eslint-disable-next-line no-undef
      // var table = new Tabulator("#example-table", {
      // eslint-disable-next-line no-undef
      self.tableAcknowledge = new Tabulator(self.$refs.tableAcknowledge, {
        height: "311px",
        layout: "fitColumns",
        reactiveData: true, //turn on data reactivity
        data: tabledata.filter(a => a.consultAck != 'T'), //load data into table
        columns: [
            {
              formatter: acknowledgeIcon,
              width: 140,
              hozAlign: "center",
              cellClick: function (e, cell) {
                const projectName = cell.getRow().getData().projectName;
                const fullname = cell.getRow().getData().fullname;
                self.$router.push({name:'SowForm',params: {id:projectName,fullname:fullname}});
              },
            },
          { title: "Project WBS Number", field: "wbsCode", sorter: "string", width: 200 },
          {
            title: "Project Name",
            field: "projectName",
            sorter: "string",
            width: 400,
          },
          {
            title: "Acknowledge Date",
            field: "consultAckDate",
            sorter: "date",
            formatter: dateIsoFormatter,
            sorterParams: {
              format: "iso",
            },
            width:200,
          },
          {
            title: "PSP Apporve Date",
            field: "pspAckDate",
            sorter: "date",
            formatter: dateIsoFormatter,
            sorterParams: {
              format: "iso",
            },
            width:200,
          },
        ],
      });
      self.tablePspApprove = new Tabulator(self.$refs.tablePspApprove, {
        height: "311px",
        layout: "fitColumns",
        reactiveData: true, //turn on data reactivity
        data: tabledata.filter(a => a.consultAck == 'T' && a.pspAck != 'T'), //load data into table
        columns: [
            {
              formatter: viewIcon,
              width: 140,
              hozAlign: "center",
              cellClick: function (e, cell) {
                const projectName = cell.getRow().getData().projectName;
                const fullname = cell.getRow().getData().fullname;
                self.$router.push({name:'SowForm',params: {id:projectName,fullname:fullname}});
              },
            },
          { title: "Project WBS Number", field: "wbsCode", sorter: "string", width: 200 },
          {
            title: "Project Name",
            field: "projectName",
            sorter: "string",
            width: 400,
          },
          {
            title: "Acknowledge Date",
            field: "consultAckDate",
            sorter: "date",
            formatter: dateIsoFormatter,
            sorterParams: {
              format: "iso",
            },
            width:200,
          },
          {
            title: "PSP Apporve Date",
            field: "pspAckDate",
            sorter: "date",
            formatter: dateIsoFormatter,
            sorterParams: {
              format: "iso",
            },
            width:200,
          },
        ],
      });
      self.tableCompleted = new Tabulator(self.$refs.tableCompleted, {
        height: "311px",
        layout: "fitColumns",
        reactiveData: true, //turn on data reactivity
        data: tabledata.filter(a => a.consultAck == 'T' && a.pspAck == 'T'), //load data into table
        columns: [
            {
              formatter: viewIcon,
              width: 140,
              hozAlign: "center",
              cellClick: function (e, cell) {
                const projectName = cell.getRow().getData().projectName;
                const fullname = cell.getRow().getData().fullname;
                self.$router.push({name:'SowForm',params: {id:projectName,fullname:fullname}});
              },
            },
          { title: "Project WBS Number", field: "wbsCode", sorter: "string", width: 200 },
          {
            title: "Project Name",
            field: "projectName",
            sorter: "string",
            width: 400,
          },
          {
            title: "Acknowledge Date",
            field: "consultAckDate",
            sorter: "date",
            formatter: dateIsoFormatter,
            sorterParams: {
              format: "iso",
            },
            width:200,
          },
          {
            title: "PSP Apporve Date",
            field: "pspAckDate",
            sorter: "date",
            formatter: dateIsoFormatter,
            sorterParams: {
              format: "iso",
            },
            width:200,
          },
        ],
      });
    },
  },
  watch: {
    inputItems: {
      handler: function (newData) {
        this.bindTable(newData);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.input-group-append {
  cursor: pointer;
}
.container {
  margin-top: 60px;
}
</style>