
import axios from "axios";
import apiService from '../services/api.service'
const API_URL = apiService.getApiUrl();
class DeliverableService{
    getData(projectID){
        return axios.get(API_URL + "deliverable/" + projectID,{
            projectID: projectID
        })
    }
    saveDeliverable(data){
        return axios.post(API_URL + "saveDeliverable",{
            deliverables: data,
        });
    }
    removeData(data){
        return axios.post(API_URL + 'deliverablesRemove',{
            deliverables: data,
        })
    }
}
export default new DeliverableService();