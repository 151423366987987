
<template>
  <div class="container-fluid">
    <h3 class="col-12 text-left">Service Description</h3>
    <div class="excel" style="padding-top: 10px">
      <div ref="divExcelService" class="hot"></div>
    </div>
    <div style="padding-top: 10px">
      <button type="button" class="btn btn-primary" @click="submitService">
        Submit
      </button>
    </div>
    <vue-final-modal
      v-model="onProcess"
      class="f-modal-container"
      content-class="f-modal-content"
    >
      <span class="modal__title">{{ saveMsg }}</span>
    </vue-final-modal>
  </div>
</template>
<script>
const emptyValidator = function (value, callback) {
  if (!value || 0 === value.length) {
    callback(false);
  } else {
    callback(true);
  }
  // callback(/^\s*$/.test(value) ? false : true);
};
import ServiceService from "../services/service.service";
import { ref, watch, onMounted } from "vue";
export default {
  name: "ServiceSetup",
  props: {
    projectID: String,
  },
  setup(props) {
    const divExcelService = ref();
    const title = ref(props.projectID);
    const hot = ref(null);
    const clmsLF = ref([
      {
        data: "description",
        required: true,
        validator: emptyValidator,
      },
      {
          data: "amount",
          type: 'numeric',
      }
    ]);
    const clmshLF = ref(["Service Description","Amount"]);
    const clmsw = ref([420,120]);
    const itemsLF = ref([
      {
        projectID: "",
        description: "",
        amount:"",
        PK: "",
      },
    ]);
    const clmReadOnly = ref([]);
    const onProcess = ref(false);
    const saveItems = ref([]);
    const saveMsg = ref("");
    // genExcel()
    onMounted(() => {
      console.log(divExcelService.value);
      genExcel();
      getData();
    });
    watch(
      () => props.projectID,
      (value, oldValue) => {
        if (value != oldValue) getData();
      }
    );

    function getData() {
      if (hot.value == null) genExcel();
      ServiceService.getData(props.projectID).then((response) => {
        var data = response.data;
        itemsLF.value = [];
        itemsLF.value = data;
        // itemsLF.value.forEach(item => item.PK = item.projectID)
        hot.value.loadData(itemsLF.value);
      });
    }
    function genExcel() {
      // Handsontable options
      var AddNew = true;
      var irows = itemsLF.value.length;
      var iclms = clmsLF.value;
      var _clmReadonly = clmReadOnly.value;
      var option = {
        data: itemsLF.value, //output,
        startRows: irows,
        startCols: iclms,
        minCols: clmsLF.value.length,
        maxRows: AddNew == true ? Infinity : irows,
        maxCols: clmsLF.value.length,
        rowHeaders: true,
        colHeaders: clmshLF.value,
        columns: clmsLF.value,
        minSpareRows: AddNew == true ? 5 : 0,
        contextMenu: ["row_above", "row_below", "remove_row"],
        renderAllRows: false,
        colWidths: clmsw.value,
        beforeRemoveRow: function (index, amount) {
          if (confirm("Do you want to delete rows?") == false) return false;
          var jsonRemove = [];
          var iIndex = index;
          for (var i = 0; i < amount; i++) {
            var element = itemsLF.value[iIndex];
            jsonRemove.push({ PK: element.PK });
            iIndex++;
          }
          removeRow(jsonRemove);
        },
        afterChange: function (change) {
          if (change != undefined) {
            afterChange(change);
          }
        },
        cells: function (row, col, prop) {
          var cellProperties = {};
          if (_clmReadonly == undefined) _clmReadonly = [];
          var found = _clmReadonly.find(function (element) {
            return element == prop;
          });
          if (found != undefined) {
            if (itemsLF.value[row] != undefined) {
              cellProperties.className = "myrowreadonly";
              cellProperties.readOnly = true;
            }
          }
          //Add by jo 08112020 for set row  color every 4 rows
          if (itemsLF.value[row] != undefined) {
            if (
              itemsLF.value[row].IS_READONLY != null ||
              itemsLF.value[row].IS_READONLY != undefined
            ) {
              if (itemsLF.value[row].IS_READONLY == "T") {
                cellProperties.readOnly = true;
                // if (cellProperties.readOnly == true)
                if (found != undefined)
                  cellProperties.className = "myrowreadonly";
                else cellProperties.className = "myrowdata";
              } else {
                if (cellProperties.readOnly == true)
                  cellProperties.className = "myreadonly";
              }
            }
          }
          //End of Add by jo 08112020 for set row  color every 4 rows
          if (col % 3 === 0) cellProperties.className = "htRight";
          if (col % 1 === 0) cellProperties.className = "htLeft";
          if (col % 0 === 0) cellProperties.className = "htLeft";
          if (col % 2 === 0) cellProperties.className = "htLeft";
          return cellProperties;
        },
        afterValidate: function (isValid, value, row) {
          if (
            row >=
            hot.value.countRows() - hot.value.getSettings().minSpareRows
          ) {
            return true;
          }
        },
        // ,
      };

      // Instantiate
      //   document.getElementById("divExcelService").innerHTML = "";
      divExcelService.value.innerHTML = "";
      // eslint-disable-next-line no-undef
      var ht = new Handsontable(
        // document.getElementById("divExcelService"),
        divExcelService.value,
        option
      );
      hot.value = ht;
    }
    function afterChange(d) {
      var uniqueItems = d
        .map((item) => item[0])
        .filter((value, index, self) => self.indexOf(value) === index);
      uniqueItems.forEach((changeItem) => {
        var index = changeItem;
        var saveItem = itemsLF.value[index];
        saveItem.index = index;
        if (saveItems.value.filter((a) => a.index == index).length == 0)
          saveItems.value.push(saveItem);
      });
    }
    function removeRow(d) {
      onProcess.value = true;
      saveMsg.value = "Deleting...";
      ServiceService.removeData(d).then((response) => {
        var data = response.data;
        saveMsg.value = data.RESULT_MSG;
        setTimeout(() => (onProcess.value = false), 3000);
      });
    }
    function submitService() {
      onProcess.value = true;
      saveMsg.value = "Saving...";
      saveItems.value.forEach((item, index) => {
        item.projectID = props.projectID;
        item.seq = index + 1;
      });
      ServiceService.saveService(saveItems.value).then((response) => {
        var data = response.data;
        saveMsg.value = data.RESULT_MSG;
        setTimeout(() => {
          onProcess.value = false;
          saveItems.value = [];
          getData();
        }, 3000);
      });

      return;
    }
    return {
      title,
      hot,
      clmsLF,
      clmshLF,
      clmsw,
      itemsLF,
      clmReadOnly,
      onProcess,
      saveItems,
      saveMsg,
      genExcel,
      afterChange,
      removeRow,
      submitService,
      divExcelService,
    };
  },
};
</script>

<style scoped>
.input-group-append {
  cursor: pointer;
}
.container {
  margin-top: 60px;
}
</style>

<style scoped>
::v-deep .f-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .f-modal-content {
  display: flex !important;
  flex-direction: column !important;
  margin: 0 1rem !important;
  padding: 1rem !important;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  font-size: 1.5rem;
  font-weight: 700;
}
</style>

<style scoped>
.dark-mode div::v-deep .f-modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>