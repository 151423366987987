/* eslint-disable no-unused-vars */


<template>
  <section class="container">
    <div class="row">
      <div class="col-4">
        <router-link to="/admin" class="btn btn-warning text-left"
          >Back to main menu</router-link
        >
      </div>
      <div class="col-6">
        <h2 class="py-2">Admin Transfer Report</h2>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-1"></div>
      <div class="col-11">
        <!-- <form class="row mb-3"> -->
        <Form @submit="adminTransferReport">
          <!-- <div class="row">
                <label for="name" class="col-2 col-form-label text-left">Project:</label>
                <div class="col-10">
                    <Field name="name" type="text" class="form-control"/>
                    <ErrorMessage name="name" class="error-feedback"/>
                </div> 
            </div> -->
          <div class="row" style="padding-top: 5px">
            <label for="date" class="col-2 col-form-label text-left"
              >User</label
            >
            <div class="col-4" style="text-align: left">
              <select style="width: 250px" v-model="userSelected">
                <option value="">All</option>
                <option
                  v-for="(item, index) in users"
                  :value="item.username"
                  :key="index"
                >
                  {{ item.username }}
                </option>
              </select>
            </div>
          </div>
          <div class="row" style="padding-top: 5px">
            <label for="date" class="col-2 col-form-label text-left"
              >Transfer From Date</label
            >
            <div class="col-2">
              <div class="input-group date" id="datepicker">
                <datepicker
                  id="date"
                  :calendar-button="true"
                  caledar-button-icon="fa fa-calendar"
                  class="form-control"
                  v-model="fromDate"
                ></datepicker>
              </div>
            </div>
          </div>
          <div class="row" style="padding-top: 5px">
            <label for="date" class="col-2 col-form-label text-left"
              >Transfer To Date</label
            >
            <div class="col-2">
              <div class="input-group date" id="datepicker">
                <datepicker
                  id="date"
                  :calendar-button="true"
                  caledar-button-icon="fa fa-calendar"
                  class="form-control"
                  v-model="toDate"
                ></datepicker>
              </div>
            </div>
            <div class="col-8">
              <div class="btn-group">
                <button class="btn btn-primary text-right">Fetch</button>
                <button
                  class="btn btn-secondary text-left"
                  @click.prevent="exportToBank"
                >
                  <font-awesome-icon icon="file-excel"></font-awesome-icon>
                  Export to Bank
                </button>
                <button
                  class="btn btn-info text-left"
                  @click.prevent="exportToCsv"
                >
                  <font-awesome-icon icon="file-excel"></font-awesome-icon>
                  Export to CSV
                </button>
              </div>
            </div>
          </div>
        </Form>
        <!-- </form> -->
      </div>
    </div>
  </section>
  <section class="container-fluid">
    <div class="row mb-3">
      <div class="col-12">
        <h4 class="text-left">
          Transfer Data at {{ toLocalDate(fromDate) }} :
        </h4>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <h4 class="text-warning text-left">Waiting to transfer :</h4>
        <div ref="table"></div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <h4 class="text-success text-left">Transfered completed :</h4>
        <div ref="tableTransfered"></div>
      </div>
    </div>
  </section>
</template>
<script>
import { ref } from "vue";
import { Form } from "vee-validate";
import Datepicker from "vue3-datepicker";
import DataService from "../services/data.service";
import UserService from "../services/user.service";
import { Parser } from "json2csv";
import dateService from "../services/date.service";
// eslint-disable-next-line no-unused-vars
var dateFormatter = function (cell, formatterParams) {
  var value = cell.getValue();

  if (value) {
    // eslint-disable-next-line no-undef
    value = moment(value, "YYYY/MM/DD").format("MM/DD/YYYY");
  }

  return value;
};
var dateIsoFormatter = function (cell) {
  var value = cell.getValue();

  if (value) {
    // eslint-disable-next-line no-undef
    value = moment(value).format("MM/DD/YYYY HH:mm:ss");
  }

  return value;
};
// eslint-disable-next-line no-unused-vars
var transferIcon = function (cell, formatterParams, onRendered) {
  // return "<font-awesome-icon icon='home' />";
  // return '<label>Hello World</label>'
  // return '<svg class="svg-inline--fa fa-house" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="house" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path class="" fill="currentColor" d="M575.8 255.5C575.8 273.5 560.8 287.6 543.8 287.6H511.8L512.5 447.7C512.5 450.5 512.3 453.1 512 455.8V472C512 494.1 494.1 512 472 512H456C454.9 512 453.8 511.1 452.7 511.9C451.3 511.1 449.9 512 448.5 512H392C369.9 512 352 494.1 352 472V384C352 366.3 337.7 352 320 352H256C238.3 352 224 366.3 224 384V472C224 494.1 206.1 512 184 512H128.1C126.6 512 125.1 511.9 123.6 511.8C122.4 511.9 121.2 512 120 512H104C81.91 512 64 494.1 64 472V360C64 359.1 64.03 358.1 64.09 357.2V287.6H32.05C14.02 287.6 0 273.5 0 255.5C0 246.5 3.004 238.5 10.01 231.5L266.4 8.016C273.4 1.002 281.4 0 288.4 0C295.4 0 303.4 2.004 309.5 7.014L564.8 231.5C572.8 238.5 576.9 246.5 575.8 255.5L575.8 255.5z"></path></svg>'
  return '<svg class="svg-inline--fa fa-money-bill-transfer" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="money-bill-transfer" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" style="color: rgb(71, 143, 124);"><path class="" fill="currentColor" d="M535 7.03C544.4-2.343 559.6-2.343 568.1 7.029L632.1 71.02C637.5 75.52 640 81.63 640 87.99C640 94.36 637.5 100.5 632.1 104.1L568.1 168.1C559.6 178.3 544.4 178.3 535 168.1C525.7 159.6 525.7 144.4 535 135L558.1 111.1L384 111.1C370.7 111.1 360 101.2 360 87.99C360 74.74 370.7 63.99 384 63.99L558.1 63.1L535 40.97C525.7 31.6 525.7 16.4 535 7.03V7.03zM104.1 376.1L81.94 400L255.1 399.1C269.3 399.1 279.1 410.7 279.1 423.1C279.1 437.2 269.3 447.1 255.1 447.1L81.95 448L104.1 471C114.3 480.4 114.3 495.6 104.1 504.1C95.6 514.3 80.4 514.3 71.03 504.1L7.029 440.1C2.528 436.5-.0003 430.4 0 423.1C0 417.6 2.529 411.5 7.03 407L71.03 343C80.4 333.7 95.6 333.7 104.1 343C114.3 352.4 114.3 367.6 104.1 376.1H104.1zM95.1 64H337.9C334.1 71.18 332 79.34 332 87.1C332 116.7 355.3 139.1 384 139.1L481.1 139.1C484.4 157.5 494.9 172.5 509.4 181.9C511.1 184.3 513.1 186.6 515.2 188.8C535.5 209.1 568.5 209.1 588.8 188.8L608 169.5V384C608 419.3 579.3 448 544 448H302.1C305.9 440.8 307.1 432.7 307.1 423.1C307.1 395.3 284.7 371.1 255.1 371.1L158.9 372C155.5 354.5 145.1 339.5 130.6 330.1C128.9 327.7 126.9 325.4 124.8 323.2C104.5 302.9 71.54 302.9 51.23 323.2L31.1 342.5V128C31.1 92.65 60.65 64 95.1 64V64zM95.1 192C131.3 192 159.1 163.3 159.1 128H95.1V192zM544 384V320C508.7 320 480 348.7 480 384H544zM319.1 352C373 352 416 309 416 256C416 202.1 373 160 319.1 160C266.1 160 223.1 202.1 223.1 256C223.1 309 266.1 352 319.1 352z"></path></svg>';
};
export default {
  name: "AdminTransferReport",
  components: {
    Datepicker,
    Form,
  },
  data() {
    return {
      fromDate: ref(new Date()),
      toDate: ref(new Date()),
      userName: "",
      inputItems: [],
      table: null,
      iconC: "red",
      iconW: "45px",
      iconH: "45px",
      currentDate: "",
      users: [],
      userSelected: "",
      tableTransfered: null,
      inputItemsTransfered: [],
    };
  },
  mounted() {
    var self = this;
    self.getUsers();
    self.userName = this.$store.state.auth.user.username;
    // eslint-disable-next-line no-undef
    var day = moment().toDate();
    // eslint-disable-next-line no-undef
    self.currentDate = moment(day).format("MM/DD/YYYY");

    //custom date formatter
    // eslint-disable-next-line no-unused-vars
    var dateFormatter = function (cell, formatterParams) {
      var value = cell.getValue();

      if (value) {
        // eslint-disable-next-line no-undef
        value = moment(value, "YYYY/MM/DD").format("MM/DD/YYYY");
      }

      return value;
    };

    //define data
    var tabledata = [];

    self.inputItems = tabledata;
    //Build Tabulator
    // eslint-disable-next-line no-undef
    const table = new Tabulator(self.$refs.table, {
      height: "311px",
      layout: "fitColumns",
      reactiveData: true, //turn on data reactivity
      data: tabledata, //load data into table
      columns: [
        {
          formatter: transferIcon,
          width: 40,
          hozAlign: "center",
          cellClick: function (e, cell) {
            if (confirm("Are you sure you want to transfer this entry?")) {
              var recid = cell.getRow().getData().recid;
              DataService.saveTransfer({
                Recid: recid,
                transfered: "T",
                transferedBy: self.userName,
              }).then((response) => {
                var result = response.data;
                if (result != undefined) self.adminTransferReport();
              });
            }
          },
        },
        {
          title: "Transfered",
          field: "transfered",
          sorter: "string",
          width: 60,
        },
        { title: "Project", field: "project", sorter: "string", width: 100 },
        {
          title: "First Name",
          field: "firstname",
          sorter: "string",
          width: 100,
        },
        { title: "Last Name", field: "lastname", sorter: "string", width: 100 },
        { title: "Account", field: "bankAccount", sorter: "string" },
        { title: "Prompt Pay", field: "promptpay", sorter: "string" },
        // { title: "G-Wallet", field: "gwallet", sorter: "string" },
        {
          title: "Date",
          field: "transferDate",
          sorter: "date",
          formatter: dateFormatter,
          sorterParams: {
            format: "iso",
          },
        },
        { title: "Bank Name", field: "bankName", sorter: "string" },
        // { title: "Branch Name", field: "branchName", sorter: "string" },
        // { title: "Branch Code", field: "branchCode", sorter: "string" },
        { title: "SMS", field: "tel", sorter: "string" },
        { title: "Email", field: "email", sorter: "string" },
        { title: "Amount", field: "amount", sorter: "number" },
        {
          title: "Created Date",
          field: "createdDate",
          sorter: "date",
          formatter: dateIsoFormatter,
          sorterParams: {
            format: "iso",
          },
        },
        { title: "Created By", field: "createdBy", sorter: "string" },
      ],
    });
    self.table = table;
    //Transfered Completed table
    //define data
    var tabledataTransfer = [];
    self.inputItemsTransfered = tabledataTransfer;
    //Build Tabulator
    // eslint-disable-next-line no-undef
    const tableTransfered = new Tabulator(self.$refs.tableTransfered, {
      height: "311px",
      layout: "fitColumns",
      reactiveData: true, //turn on data reactivity
      data: tabledataTransfer, //load data into table
      columns: [
        { title: "Project", field: "project", sorter: "string", width: 100 },
        {
          title: "First Name",
          field: "firstname",
          sorter: "string",
          width: 100,
        },
        { title: "Last Name", field: "lastname", sorter: "string", width: 100 },
        { title: "Account", field: "bankAccount", sorter: "string" },
        { title: "Prompt Pay", field: "promptpay", sorter: "string" },
        // { title: "G-Wallet", field: "gwallet", sorter: "string" },
        {
          title: "Date",
          field: "transferDate",
          sorter: "date",
          formatter: dateFormatter,
          sorterParams: {
            format: "iso",
          },
        },
        { title: "Bank Name", field: "bankName", sorter: "string" },
        // { title: "Branch Name", field: "branchName", sorter: "string" },
        // { title: "Branch Code", field: "branchCode", sorter: "string" },
        { title: "SMS", field: "tel", sorter: "string" },
        { title: "Email", field: "email", sorter: "string" },
        { title: "Amount", field: "amount", sorter: "number" },
        {
          title: "Created Date",
          field: "createdDate",
          sorter: "date",
          formatter: dateIsoFormatter,
          sorterParams: {
            format: "iso",
          },
        },
        { title: "Created By", field: "createdBy", sorter: "string" },
      ],
    });
    self.tableTransfered = tableTransfered;
  },
  methods: {
    exportToBank() {
      var self = this;
      self.inputItems = [];
      var data = {
        fromDate: self.toLocalDate(self.fromDate),
        toDate: self.toLocalDate(self.toDate),
        createdBy: self.userSelected,
      };
      DataService.transferReportAll(data).then((response) => {
        var result = response.data;
        result.forEach((element, index) => {
          element.seq = index + 1;
          element.bankAccount = element.bankAccount == null ? '' : element.bankAccount;
          element.promptpay = element.promptpay == null ? '' : element.promptpay;
          element.gwallet = element.gwallet == null ? '' : element.gwallet;
          element.accountExport = element.bankAccount;
          if (element.promptpay != "")
            element.accountExport +=
              element.accountExport != ""
                ? " / " + element.promptpay
                : element.promptpay;
          if (element.gwallet != "")
            element.accountExport +=
              element.accountExport != ""
                ? " / " + element.gwallet
                : element.gwallet;
          element.recipientName = element.firstname + " " + element.lastname;
          element.customerTransectionRef = "";
          element.feeChange = "Payer (OUR)";
          element.serviceType = "";
          element.remark = "";
          element.transferDate = new Date(element.transferDate);
          element.createdDate = new Date(element.createdDate);
          element.tel = "'" + element.tel;
        });
        var resultWaitTransferd = result.filter((d) => d.transfered != "T");
        var resultTransfered = result.filter((d) => d.transfered == "T");
        self.inputItems = resultWaitTransferd;
        self.inputItemsTransfered = resultTransfered;
        const fields = [
          {
            label: "Seq",
            value: "seq",
          },
          {
            label: "Bank Name",
            value: "bankName",
          },
          {
            label: "Account / Prompt Pay",
            value: "accountExport",
          },
          {
            label: "Recipient Name",
            value: "recipientName",
          },
          {
            label: "Amount",
            value: "amount",
          },
          {
            label: "Custormer Transaction Ref.",
            value: "customerTransectionRef",
          },
          {
            label: "Fee Charge",
            value: "feeChange",
          },
          {
            label: "Branch Code",
            value: "branchCode",
          },
          {
            label: "Service Type",
            value: "serviceType",
          },
          {
            label: "SMS Notification",
            value: "tel",
          },
          {
            label: "Email Notification/Receiving Document",
            value: "email",
          },
          {
            label: "Payment Advice Remark (Txn lavel)(=<200 chars)",
            value: "remark",
          },
        ];
        const json2csvParser = new Parser({ fields: fields });
        var BOM = "\uFEFF"; 
        const csv = BOM + json2csvParser.parse(result);
        var exportedFilenmae =
          "Transfer2Bank_at_" + dateService.toLocalDate(self.fromDate) + ".csv" ||
          "export.csv";

        var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
          var link = document.createElement("a");
          if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      });
    },
    exportToCsv() {
      var self = this;
      var data = {
        fromDate: self.toLocalDate(self.fromDate),
        toDate: self.toLocalDate(self.toDate),
        createdBy: self.userSelected,
      };
      DataService.transferReportAll(data).then((response) => {
        var result = response.data;
        console.log(response.data)
        result.forEach((element, index) => {
          element.seq = index + 1
          element.transferedStatus = element.transfered == 'T' ? 'Completed' : 'Waiting';
          element.transferDate = new Date(element.transferDate);
          element.transferDate = dateService.toLocalDate(element.transferDate)
          element.createdDate = new Date(element.createdDate);
          element.createdDate = dateService.toLocalDate(element.createdDate)
          element.tel = "'" + element.tel;
        });
        console.log(result)
        const fields = [
          {
            label: "Transfered",
            value: "transferedStatus",
          },
          {
            label: "Project",
            value: "project",
          },
          {
            label: "First Name",
            value: "firstname",
          },
          {
            label: "Last Name",
            value: "lastname",
          },
          {
            label: "Account",
            value: "bankAccount",
          },
          {
            label: "Bank Name",
            value: "bankName",
          },
          {
            label: "Prompt Pay",
            value: "promptpay",
          },
          // {
          //   label: "G-Wallet",
          //   value: "gwallet",
          // },
          {
            label: "Transfer Date",
            value: "transferDate",
          },
          {
            label: "SMS",
            value: "tel",
          },
          {
            label: "Email",
            value: "email",
          },
          {
            label: "Amount",
            value: "amount",
          },
          {
            label: "Created Date",
            value: "createdDate",
          },
          {
            label: "Created By",
            value: "createdBy",
          }
        ];
        const json2csvParser = new Parser({ fields: fields });
        var BOM = "\uFEFF"; 
        const csv = BOM + json2csvParser.parse(result);
        var exportedFilenmae =
          "Transfer2Csv_at_" + dateService.toLocalDate(self.fromDate)+ "_" + dateService.toLocalDate(self.toDate) + ".csv" ||
          "export.csv";

        var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
          var link = document.createElement("a");
          if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      });
    },
    toLocalDate(dateStr) {
      const date = new Date(dateStr);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var dt = date.getDate();

      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) month = "0" + month;
      return month + "/" + dt + "/" + year;
    },
    getUsers() {
      var self = this;
      UserService.getUsers().then((response) => {
        var result = response.data;
        self.users = result;
      });
    },
    adminTransferReport() {
      var self = this;
      self.inputItems = [];
      var data = {
        fromDate: self.toLocalDate(self.fromDate),
        toDate: self.toLocalDate(self.toDate),
        createdBy: self.userSelected,
      };
      DataService.transferReportAll(data).then((response) => {
        var result = response.data;
        result.forEach((element) => {
          element.transferDate = new Date(element.transferDate);
          element.createdDate = new Date(element.createdDate);
        });
        var resultWaitTransferd = result.filter((d) => d.transfered != "T");
        var resultTransfered = result.filter((d) => d.transfered == "T");
        self.inputItems = resultWaitTransferd;
        self.inputItemsTransfered = resultTransfered;
      });
    },
    bindTable(d) {
      var self = this;
      //define data
      var tabledata = d;

      self.inputItems = tabledata;
      //Build Tabulator
      // eslint-disable-next-line no-undef
      // var table = new Tabulator("#example-table", {
      // eslint-disable-next-line no-undef
      self.table = new Tabulator(self.$refs.table, {
        height: "311px",
        layout: "fitColumns",
        reactiveData: true, //turn on data reactivity
        data: tabledata, //load data into table
        columns: [
          {
            formatter: transferIcon,
            width: 40,
            hozAlign: "center",
            cellClick: function (e, cell) {
              if (confirm("Are you sure you want to transfer this entry?")) {
                var recid = cell.getRow().getData().recid;
                DataService.saveTransfer({
                  Recid: recid,
                  transfered: "T",
                  transferedBy: self.userName,
                }).then((response) => {
                  var result = response.data;
                  if (result != undefined) self.adminTransferReport();
                });
              }
            },
          },
          { title: "Project", field: "project", sorter: "string", width: 100 },
          {
            title: "First Name",
            field: "firstname",
            sorter: "string",
            width: 100,
          },
          {
            title: "Last Name",
            field: "lastname",
            sorter: "string",
            width: 100,
          },
          { title: "Account", field: "bankAccount", sorter: "string" },
          { title: "Prompt Pay", field: "promptpay", sorter: "string" },
          // { title: "G-Wallet", field: "gwallet", sorter: "string" },
          {
            title: "Date",
            field: "transferDate",
            sorter: "date",
            formatter: dateFormatter,

            sorterParams: {
              format: "iso",
            },
          },
          { title: "Bank Name", field: "bankName", sorter: "string" },
          // { title: "Branch Name", field: "branchName", sorter: "string" },
          // { title: "Branch Code", field: "branchCode", sorter: "string" },
          { title: "SMS", field: "tel", sorter: "string" },
          { title: "Email", field: "email", sorter: "string" },
          { title: "Amount", field: "amount", sorter: "number" },
          {
            title: "Created Date",
            field: "createdDate",
            sorter: "date",
            formatter: dateIsoFormatter,
            sorterParams: {
              format: "iso",
            },
          },
        { title: "Created By", field: "createdBy", sorter: "string" },
        ],
      });
    },
    bindTableTransferd(d) {
      var self = this;
      //Transfered Completed table
      //define data
      var tabledataTransfer = d;
      self.inputItemsTransfered = tabledataTransfer;
      //Build Tabulator
      // eslint-disable-next-line no-undef
      self.tableTransfered = new Tabulator(self.$refs.tableTransfered, {
        height: "311px",
        layout: "fitColumns",
        reactiveData: true, //turn on data reactivity
        data: tabledataTransfer, //load data into table
        columns: [
          { title: "Project", field: "project", sorter: "string", width: 100 },
          {
            title: "First Name",
            field: "firstname",
            sorter: "string",
            width: 100,
          },
          {
            title: "Last Name",
            field: "lastname",
            sorter: "string",
            width: 100,
          },
          { title: "Account", field: "bankAccount", sorter: "string" },
          { title: "Prompt Pay", field: "promptpay", sorter: "string" },
          // { title: "G-Wallet", field: "gwallet", sorter: "string" },
          {
            title: "Date",
            field: "transferDate",
            sorter: "date",
            formatter: dateFormatter,
            sorterParams: {
              format: "iso",
            },
          },
          { title: "Bank Name", field: "bankName", sorter: "string" },
          // { title: "Branch Name", field: "branchName", sorter: "string" },
          // { title: "Branch Code", field: "branchCode", sorter: "string" },
          { title: "SMS", field: "tel", sorter: "string" },
          { title: "Email", field: "email", sorter: "string" },
          { title: "Amount", field: "amount", sorter: "number" },
          {
            title: "Created Date",
            field: "createdDate",
            sorter: "date",
            formatter: dateIsoFormatter,
            sorterParams: {
              format: "iso",
            },
          },
        { title: "Created By", field: "createdBy", sorter: "string" },
        ],
      });
    },
  },
  watch: {
    inputItems: {
      handler: function (newData) {
        this.bindTable(newData);
      },
      deep: true,
    },
    inputItemsTransfered: {
      handler: function (newData) {
        this.bindTableTransferd(newData);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.input-group-append {
  cursor: pointer;
}
.container {
  margin-top: 60px;
}
</style>