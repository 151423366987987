<template>
  <div>
    <section class="container">
      <div class="row mb-3">
        <div class="col-12" style="text-align: left">
          <div class="row mb-3">
            <div class="col-12 col-md-4 text-left">
              <router-link to="/admin" class="btn btn-warning text-left"
                ><font-awesome-icon icon="circle-left"></font-awesome-icon> Back
                to main menu</router-link
              >
            </div>
            <div class="col-12 col-md-6">
              <h2 class="py-2">Projects Detail Setup</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="padding-top: 5px">
        <label for="project" class="col-2 col-form-label text-right"
          >Project:</label
        >
        <div class="col-12 col-md-10 text-left">
          <select
            style="height: 37px"
            class="form-select form-select-lg"
            aria-label=".form-select-lg example"
            v-model="projectSelected"
            aria-describedby="helpProject"
          >
            <option
              v-for="(item, index) in projectItems"
              :key="index"
              :value="item.projectName"
            >
              {{ item.projectName }}
            </option>
          </select>
          <small
            v-show="projectSelected == ''"
            id="helpProject"
            class="form-text text-left text-warning"
            >Please select project!</small
          >
        </div>
      </div>
      <div class="row">
        <div class="col-4 col-md-3">
          <label class="text-left col-form-label"
            >WBS Number : {{ wbsNumber }}</label
          >
        </div>
        <div class="col-4 col-md-3">
          <label class="text-left col-form-label"
            >WBS Code : {{ wbsCode }}</label
          >
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-12 col-md-3">
          <div class="row">
            <label class="col-12 col-form-label text-left">Project Leader:</label>
            <input
              type="text"
              class="col-12 form-control"
              maxlength="40"
              v-model="projectLeader"
            />
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="row">
            <label class="col-12 col-form-label text-left">Eff From:</label>
            <div class="col-12 input-group date" id="datepickerFrom">
              <datepicker
                id="dateFrom"
                :calendar-button="true"
                caledar-button-icon="fa fa-calendar"
                class="form-control"
                v-model="effFrom"
              ></datepicker>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="row">
            <label class="col-12 col-form-label text-left">Eff To:</label>
            <div class="col-12 input-group date" id="datepickerTo">
              <datepicker
                id="dateTo"
                :calendar-button="true"
                caledar-button-icon="fa fa-calendar"
                class="form-control"
                v-model="effTo"
              ></datepicker>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-3">
          <div class="row">
            <label class="col-12 col-form-label text-left">Method HCFS:</label>
            <input
              type="text"
              class="col-12 form-control"
              maxlength="40"
              v-model="hcfs"
            />
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="row">
            <label class="col-12 col-form-label text-left">Method LOI:</label>
            <input
              type="text"
              class="col-12 ml-md-3 form-control"
              maxlength="40"
              v-model="loi"
            />
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12 col-md-9">
          <div class="row">
            <label class="col-12 col-form-label text-left"
              >Service Description:</label
            >
            <textarea
              rows="3"
              cols="50"
              class="col-12 form-control"
              maxlength="400"
              v-model="serviceDesc"
            />
          </div>
          <div class="row">
            <div class="col-12 text-right">
              <button
                :disabled="projectSelected == ''"
                class="btn btn-primary mt-2"
                @click.prevent="submitProject"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row mb-3" v-show="projectSelected == ''">
          <h2>Please select project!</h2>
      </div>
      <div class="row mb-3" v-show="projectSelected != ''">
          <deriverable-setup :projectID="projectID"></deriverable-setup>
      </div>
      <div class="row mb-3" v-show="projectSelected != ''">
          <deriverable-acceptant-setup :projectID="projectID"></deriverable-acceptant-setup>
      </div>
      <div class="row mb-3" v-show="projectSelected != ''">
          <service-setup :projectID="projectID"></service-setup>
      </div>
      <div class="row mb-3" v-show="projectSelected != ''">
        <project-consult :projectID="projectID"></project-consult>
      </div>
    </section>
  </div>
</template>
<script>
import { ref, watch } from "vue";
import Datepicker from "vue3-datepicker";
import ProjectService from "../services/project.service";
import DateService from "../services/date.service";
import DeriverableSetup from "../components/DeliverableSetup.vue";
import DeriverableAcceptantSetup from "../components/DeliverableAcceptantSetup.vue";
import ServiceSetup from "../components/ServiceSetup.vue";
import ProjectConsult from "../components/ProjectConsult.vue";
//custom date formatter
// eslint-disable-next-line no-unused-vars
var dateFormatter = function (cell, formatterParams) {
  var value = cell.getValue();

  if (value) {
    // eslint-disable-next-line no-undef
    value = moment(value, "YYYY/MM/DD").format("MM/DD/YYYY");
  }

  return value;
};
export default {
  components: {
    Datepicker,
    DeriverableSetup,
    DeriverableAcceptantSetup,
    ServiceSetup,
    ProjectConsult,
  },
  setup() {
    const projectID = ref("");
    const projectSelected = ref("");
    const wbsNumber = ref("");
    const wbsCode = ref("");
    const projectItems = ref([]);
    const projectLeader = ref("");
    const effFrom = ref(new Date());
    const effTo = ref(new Date());
    const hcfs = ref("");
    const loi = ref("");
    const serviceDesc = ref("");
    watch(projectSelected, (value, oldvalue) => {
      if (value != oldvalue) {
        if (value != "") getData();
        else clearData();
        //    var aData = projectItems.value
        //    var filtered = aData.filter(y => y.projectName == value)
        //    console.log(filtered)
        //    if(filtered.lenth > 0){
        //        wbsNumber.value = filtered[0].wbsNumber
        //        wbsCode.value = filtered[0].wbsCode
        //        projectLeader.value = filtered[0].projectLeader
        //        effFrom.value = new Date(filtered[0].effFrom)
        //        effTo.value = new Date(filtered[0].effTo)
        //        hcfs.value = filtered[0].hcfs
        //        loi.value = filtered[0].loi
        //        serviceDesc.value = filtered[0].serviceDesc
        //    }
        //    else{
        //        clearData()
        //    }
      }
    });
    function clearData() {
      projectID.value = ref("");
      projectSelected.value = ref("");
      wbsNumber.value = ref("");
      wbsCode.value = ref("");
      projectItems.value = ref([]);
      projectLeader.value = ref("");
      effFrom.value = ref(new Date());
      effTo.value = ref(new Date());
      hcfs.value = ref("");
      loi.value = ref("");
      serviceDesc.value = ref("");
    }
    function getProjects() {
      ProjectService.getProjects().then((response) => {
        var data = response.data;
        if (data.RESULT_CODE == "99") {
          alert(data.RESULT_MSG);
          return;
        }
        projectItems.value = data;
      });
    }
    function getData() {
      ProjectService.getData(projectSelected.value).then((response) => {
        var data = response.data;
        if (data.RESULT_CODE == "99") {
          alert(data.RESULT_MSG);
          return;
        }
        if(data.length > 0){
            projectID.value = data[0].projectID;
            wbsNumber.value = data[0].wbsNumber;
            wbsCode.value = data[0].wbsCode;
            projectLeader.value = data[0].projectLeader;
            if(data[0].effFrom != null)
                effFrom.value = new Date(data[0].effFrom);
            else
                effFrom.value = new Date();
            
            if(data[0].effTo != null)
                effTo.value = new Date(data[0].effTo);
            else
                effTo.value = new Date();
            hcfs.value = data[0].hcfs;
            loi.value = data[0].loi;
            serviceDesc.value = data[0].serviceDesc;
        }
        else clearData()
      });
    }
    function submitProject() {
      var data = {
        projectName: projectSelected.value,
        PK: projectSelected.value,
        wbsNumber: wbsNumber.value,
        wbsCode: wbsCode.value,
        projectLeader: projectLeader.value,
        effFrom: DateService.toLocalDate(effFrom.value),
        effTo: DateService.toLocalDate(effTo.value),
        hcfs: hcfs.value,
        loi: loi.value,
        serviceDesc: serviceDesc.value,
      };
      var projects = [data];
      ProjectService.saveProject(projects).then((response) => {
        var data = response.data;
        if (data.RESULT_CODE == "99") {
          alert(data.RESULT_MSG);
        } else {
            alert("Save success");
            getData()
        }
      });
    }
    getProjects();
    return {
      projectSelected,
      wbsNumber,
      wbsCode,
      projectItems,
      projectLeader,
      getProjects,
      effFrom,
      effTo,
      hcfs,
      loi,
      serviceDesc,
      submitProject,
      clearData,
      getData,
      projectID,
    };
  },
};
</script>