<template>
    <div class="container-fluid">
        <h3 class="col-12 text-left">Project Consultant</h3>
        <div class="row mb-3">
            <div class="col-5 text-left">
                <span class="label label-danger"><h5><u>Employees</u></h5></span>
                <div v-if="0==1" class="list-group">
                    <label class="list-group-item bg-secondary" style="color:white;">
                        &nbsp;&nbsp;&nbsp;<input class="form-check-input me-1"  type="checkbox" v-model="isCheckEmpAll">
                        <b>ALL</b>{{isCheckEmpAll}}
                    </label>
                </div>
                <div class="list-group" style="min-height:400px;max-height:400px;overflow: auto">
                    <label class="list-group-item"  v-for="(empItem,index) in empItems" :key="index">
                        &nbsp;&nbsp;&nbsp;<input v-model="empSelectedItems" class="form-check-input me-1" type="checkbox" :value="empItem.username">
                        {{empItem.fullname}}
                    </label>
                </div>
                {{empSelectedItems}}
            </div>
            <div class="col-2 align-middle" style="min-height:400px;max-height:400px;">
                <div style="height:200px"></div>
                <button :disabled="empSelectedItems.length == 0" @click.prevent="addData(empSelectedItems)" type="button" class="btn btn-primary">
                    <font-awesome-icon icon="arrow-right"/>
                </button>
                <br/>
                <br/>
                <button :disabled="consultSelectedItems.length == 0" @click.prevent="removeData(consultSelectedItems)" type="button" class="btn btn-primary">
                    <font-awesome-icon icon="arrow-left"/>
                </button>
            </div>
            <div class="col-5 text-left">
                <span class="label label-danger"><h5><u>Consultants</u></h5></span>
                <div v-if="0==1" class="list-group">
                    <label class="list-group-item bg-secondary" style="color:white;">
                        &nbsp;&nbsp;&nbsp;<input class="form-check-input me-1" @click="checkAllConsult" type="checkbox" v-model="isCheckConsultAll">
                        <b>ALL</b>
                    </label>
                </div>
                <div class="list-group" style="min-height:400px;max-height:400px;overflow: auto">
                    <label class="list-group-item"  v-for="(consultItem,index) in consultItems" :key="index">
                        &nbsp;&nbsp;&nbsp;<input v-model="consultSelectedItems" class="form-check-input me-1" type="checkbox" :value="consultItem.consult">
                        {{consultItem.fullname}}
                    </label>
                </div>
                {{consultSelectedItems}}
            </div>
        </div>
        <vue-final-modal
          v-model="onProcess"
          class="f-modal-container"
          content-class="f-modal-content"
        >
          <span class="modal__title">{{ saveMsg }}</span>
        </vue-final-modal>
    </div>
</template>
<script>
import ProjectConsultService from "../services/project.consult.service"
import {ref, watch, onMounted} from "vue";
    
export default {
    name: "ProjectConsult",
    props:{
        projectID: String,
    },
    setup(props) {
        const title = ref(props.projectID);
        const empItems = ref([]);
        //list items fot add to consult
        const empSelectedItems = ref([]);
        const consultItems = ref([]);
        //list items fot add to employee
        const consultSelectedItems = ref([]);
        const onProcess = ref(false);
        const saveMsg = ref(false);
        const isCheckEmpAll = ref(false);
        const isCheckConsultAll = ref(false);
        onMounted(() => {
            getData()
        })
        watch(
            () => props.projectID,
            (value, oldValue) => {
                if(value != oldValue) getData();
            }
        )
        watch(isCheckEmpAll, (value, oldValue) => {
                if(value != oldValue){
                    if(value == true){
                        empSelectedItems.value = []
                        empItems.value.forEach(element => {
                          empSelectedItems.value.push(element);  
                        });
                    }
                    else{
                        empSelectedItems.value = []
                    }
                }
            },{
                lazy: true
            }
        )

        function getData(){
            ProjectConsultService.getData(props.projectID).then((response) =>{
                var data = response.data;
                console.log(data)
                consultSelectedItems.value = [];
                consultItems.value = data;
                getDataEmp();
            });
        }

        function getDataEmp(){
            ProjectConsultService.getDataEmp(props.projectID).then((response) =>{
                var data = response.data;
                console.log(data)
                empSelectedItems.value = [];
                empItems.value = data;
            });
        }
        
        function removeData(d) {
          onProcess.value = true;
          saveMsg.value = "Deleting...";
          ProjectConsultService.removeData(d,props.projectID).then((response) => {
            var data = response.data;
            saveMsg.value = data.RESULT_MSG;
            setTimeout(() => (onProcess.value = false), 3000);
            getData();
          });
        }

        function addData(d) {
          onProcess.value = true;
          saveMsg.value = "Saving...";
          ProjectConsultService.addData(d,props.projectID).then((response) => {
            var data = response.data;
            saveMsg.value = data.RESULT_MSG;
            setTimeout(() => (onProcess.value = false), 3000);
            getData();
          });
        }

        function checkAllConsult(){
            var self = this;
            if(self.isCheckConsultAll.value == true)
                self.consultSelectedItems.value = self.consultItems.value;
            else
                self.consultSelectedItems.value = []
        }

        function isEmpChecked(d){
            var result = empSelectedItems.value.filter(a => a.username == d)
            console.log(result.length)
            return result.length > 0;
        }
        return{
            title,
            empItems,
            empSelectedItems,
            consultItems,
            consultSelectedItems,
            onProcess,
            saveMsg,
            getData,
            getDataEmp,
            removeData,
            addData,
            isCheckEmpAll,
            isCheckConsultAll,
            // checkAllEmp,
            checkAllConsult,
            isEmpChecked
        }

    },
}
</script>