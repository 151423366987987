

<template>
    <div class="row">
        <div class="col-12" style="text-align:left">
            <div class="row">
                <div class="col-6">
                    <router-link to="/" class="btn btn-warning text-left">Back to main menu</router-link>
                </div>
                <div class="col-6">
                    <h4>Date: {{getCurrentDate()}}</h4>
                </div>
            </div>
            <div class="excel" style="padding-top:10px">
                <div id="divExcel" class="hot"></div> 
            </div>
            <div style="padding-top:10px;">
                <button type="button" class="btn btn-primary">Submit</button>
            </div>
        </div>
    </div>
</template>
<script>

// import Handsontable from '../../dist/js/vendor/handsontable.full.js';
// import ($,jQuery) from 'jquery';
// import { Handsontable } from 'dist/js/vendor/handsontable.full';
// import $ from 'jquery'
const emptyValidator = function(value, callback) {
    if (!value || 0 === value.length) {
        console.log('false');
        callback(false);
    } else {
        console.log('true');
        callback(true);
    }
    // callback(/^\s*$/.test(value) ? false : true);
};
export default({
    name: 'InputDataManage',
    data(){
        return {
            hot: null,
            clmsLF: [
                {
                    data: 'VENDOR_ID',
                    required: true,
                    validator: emptyValidator
                }, {
                    data: 'VENDOR_NAME'
                    ,required: true,
                    validator: emptyValidator
                }, {
                    data: 'TAX_ID'
                    // ,type: 'numeric'
                    ,required: true,
                    validator: emptyValidator
                } , {
                    data: 'PERSONAL_ID'
                    // ,type: 'numeric'
                    ,required: true,
                    validator: emptyValidator
                } , {
                    data: 'TEL_NO'
                    // ,type: 'numeric'
                    ,required: true,
                    validator: emptyValidator
                } , {
                    data: 'EMAIL'
                    // ,type: 'numeric'
                    ,required: true,
                    validator: emptyValidator
                } , {
                    data: 'SMS_NO'
                    // ,type: 'numeric'
                    ,required: true,
                    validator: emptyValidator
                } , {
                    data: 'SMS_LANG'
                    // ,type: 'numeric'
                    ,required: true,
                    validator: emptyValidator
                } , {
                    data: 'CREDIT_BANK_CODE'
                    // ,type: 'numeric'
                    ,required: true,
                    validator: emptyValidator
                } , {
                    data: 'CREDIT_BANK_NAME'
                    // ,type: 'numeric'
                    ,required: true,
                    validator: emptyValidator
                } , {
                    data: 'CREDIT_ACCOUNT'
                    // ,type: 'numeric'
                    ,required: true,
                    validator: emptyValidator
                } , {
                    data: 'BANK_BRANCH_CODE'
                    // ,type: 'numeric'
                    ,required: true,
                    validator: emptyValidator
                } , {
                    data: 'LOG'
                    // ,type: 'numeric'
                    ,required: true,
                    validator: emptyValidator
                } , {
                    data: 'USER_NAME'
                    // ,type: 'numeric'
                    ,required: true,
                    validator: emptyValidator
                } 
            ],
            clmshLF: [
                'Vendor', 'Vendor Name', 'Tax ID', 'Personal ID','Tel No.','E-mail','SMS No.','SMS Lang','Credit Bank Code','Credit Bank Name','Credit Account','Bank Branch Code','Log (time stamp)', 'User Name'    ],
            clmsw:[100,150,150,150,150,200,100,100,150,200,150,150,150,150],
            itemsLF: [{
                'VENDOR_ID': '',
                'VENDOR_NAME': '',
                'TAX_ID': '',
                'PERSONAL_ID': '',
                'TEL_NO': '' ,
                'EMAIL': '' ,
                'SMS_NO': '' ,
                'SMS_LANG': '' ,
                'CREDIT_BANK_CODE': '' ,
                'CREDIT_BANK_NAME': '' ,
                'CREDIT_ACCOUNT': '' ,
                'BANK_BRANCH_CDOE': '' ,
                'LOG': '' ,
                'USER_NAME': '' 
            }],
            clmReadOnly:[]
        }
    },
    props: {
        userid: String
    },
    mounted() {
        var self = this
        console.log(self.hot)
        if(self.hot == null)
            self.genExcel()
        else
            alert('Hello World')
// var data = [
//   ["", "Ford", "Tesla", "Toyota", "Honda"],
//   ["2017", 10, 11, 12, 13],
//   ["2018", 20, 11, 14, 13],
//   ["2019", 30, 15, 12, 13]
// ];

// var container = document.getElementById('example');
// var hotEX = new Handsontable(container, {
//   data: data,
//   rowHeaders: true,
//   colHeaders: true,
//   filters: true,
//   dropdownMenu: true
// });

        // console.log(Handsontable)
        // console.log(Handsontable)
    },
    methods: {
        genExcel(){
            // Handsontable options
            var self = this
            var AddNew = true
            var irows = self.itemsLF.length
            var iclms = self.clmsLF
            var _clmReadonly = self.clmReadOnly
            var option = {
                data: self.itemsLF, //output,
                startRows: irows,
                startCols: iclms,
                // minRows: 5,
                minCols: self.clmsLF.length,
                // width: _width,
                maxRows: AddNew == true ? Infinity : irows,
                maxCols: self.clmsLF.length,
                rowHeaders: true,
                colHeaders: self.clmshLF,
                columns: self.clmsLF,
                minSpareRows: AddNew == true ? 30 : 0,
                contextMenu: ['row_above','row_below','remove_row'],
                renderAllRows: false,
                colWidths: self.clmsw,
                beforeKeyDown:function(e){
                    //Add by jo 02012022 for input only numeric on Quanity column (index of 3)
                    var clm = this.getSelected()[1]
                    if(clm == 2){
                        // var reg = /^\d+$/;
                        // var reg = /\d+/g;
                        var reg = /^[0-9]*\.?[0-9]*$/;
                        if (!reg.test(e.key)) {
                          e.preventDefault()
                        }
                    }
                },
                beforeRemoveRow: function(index, amount) {
                    if(self.isValidate == false){
                        if (confirm('Do you want to delete rows?') == false)
                            return false;
                    }
                    self.isValidate = true
                    var jsonRemove = [];
                    var iIndex = index;
                    for (var i = 0; i < amount; i++) {
                        var element = self.itemsLF[iIndex];
                        jsonRemove.push(element);
                        iIndex++;
                    }
                    // self.$emit('removerow', jsonRemove)
                },
                afterChange: function(change) {
                    if (change != undefined) {
                        change.forEach(function(element) {
                            if (element[0] != undefined) {
                                self.afterChange(change)
                            }
                        }, this);
                    }
                },
                cells: function(row, col, prop) {
                    var cellProperties = {};
                    if(_clmReadonly == undefined)
                        _clmReadonly = []
                    var found = _clmReadonly.find(function(element) {
                        return element == prop;
                      });
                    if(found != undefined){
                        if (self.itemsLF[row] != undefined) {
                                cellProperties.className = 'myrowreadonly';
                                cellProperties.readOnly = true
                            // if (self.$props.items[row].PK != undefined && self.$props.items[row].PK != '')
                            //     cellProperties.readOnly = true
                            // else
                            //     cellProperties.readOnly = false
                        }
                    }
                    //Add by jo 08112020 for set row  color every 4 rows
                    if(self.itemsLF[row] != undefined){
                        if (self.itemsLF[row].IS_READONLY != null || self.itemsLF[row].IS_READONLY != undefined) {
                            if (self.itemsLF[row].IS_READONLY == 'T') {
                                cellProperties.readOnly = true;
                                // if (cellProperties.readOnly == true)
                                if(found != undefined)
                                    cellProperties.className = 'myrowreadonly';
                                else
                                    cellProperties.className = 'myrowdata';
                            } else {
                                if (cellProperties.readOnly == true)
                                    cellProperties.className = 'myreadonly'
                            }
            
                        }
                    }
                    //End of Add by jo 08112020 for set row  color every 4 rows
                    if(col%3 === 0)
                        cellProperties.className = 'htRight'
                    return cellProperties
                },
                afterValidate: function(isValid,value,row){
                    if(row >= self.hot.countRows() - self.hot.getSettings().minSpareRows){
                        return true;
                    }
                }
                // ,
                // beforeValidate: function(val, row, prop){
                //     if(val === ''){
                //         return 0
                //     }
                // }

            };
            
            // Instantiate
            console.log(document.getElementById('divExcel'))
            // eslint-disable-next-line no-undef
            var ht = new Handsontable(document.getElementById('divExcel'), option);
            self.hot = ht
        },
        afterChange(d){
            console.log(d)
            // if(d != undefined){
            //    var self = this
            //    d.forEach(function(element){
            //         if(element[1].toLowerCase() == 'store_location'){
            //             var aresult = self.filterByClm(self.storeLocationItems,'LOCATION',element[3])
            //             self.itemsLF[element[0]].STORE_DESCRIPTION = aresult[0].DESCRIPTION
            //         }
            //    });
            //    self.hot.loadData(self.itemsLF)
            // }
        },
        getCurrentDate(){
           let currentDate = new Date();
            let cDay = currentDate.getDate();
            let cMonth = currentDate.getMonth() + 1;
            let cYear = currentDate.getFullYear();
            return cDay + "/" + cMonth + "/" + cYear
        }
    },
})
</script>