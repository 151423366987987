


<template>
<div>
  <section class="container">
    <div class="row mb-3">
      <div class="col-12" style="text-align: left">
        <div class="row mb-3">
          <div class="col-12 col-md-4 text-left">
            <router-link to="/admin" class="btn btn-warning text-left"
              ><font-awesome-icon icon="circle-left"></font-awesome-icon> Back to main menu</router-link
            >
          </div>
          <div class="col-12 col-md-6">
                <h2 class="py-2">Projects Master Setup</h2>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="container-fluid">
    <div class="excel" style="padding-top: 10px">
      <div id="divExcelProject" class="hot"></div>
    </div>
    <div style="padding-top: 10px">
      <button type="button" class="btn btn-primary" @click="submitProject">
        Submit
      </button>
    </div>
  <vue-final-modal v-model="onProcess" class="f-modal-container" content-class="f-modal-content">
      <span class="modal__title">{{saveMsg}}</span>
  </vue-final-modal>
  </section>
</div>
</template>
<script>
const emptyValidator = function (value, callback) {
  if (!value || 0 === value.length) {
    callback(false);
  } else {
    callback(true);
  }
  // callback(/^\s*$/.test(value) ? false : true);
};
import ProjectService from "../services/project.service";
export default {
  name: "ProjectSetup",
  data() {
    return {
      hot: null,
      clmsLF: [
        {
          data: "wbsNumber",
          // required: true,
          // validator: emptyValidator,
        },
        {
          data: "projectName",
          required: true,
          validator: emptyValidator,
        },
        {
          data: "wbsCode",
          // required: true,
          // validator: emptyValidator,
        },
        {
          data: "seq",
          type: 'numeric',
          // validator: emptyValidator,
        },
      ],
      clmshLF: [
        "WBS Number",
        "Project Name",
        "WBS Code",
        "Sequence"
      ],
      clmsw: [120, 420,120,120],
      itemsLF: [
        {
          wbsNumber: "",
          projectName: "",
          wbsCode: "",
          seq: "",
          projectID: ""
        },
      ],
      clmReadOnly: [],
      onProcess: false,
      saveItems: [],
      saveMsg: ''
    };
  },
  props: {
    userid: String,
  },
  mounted() {
    var self = this;
    if (self.hot == null) self.genExcel();
    else alert("Hello World");
    ProjectService.getProjects()
      .then((response) => {
          var data = response.data
          if(data.RESULT_CODE == '99')
          {
            alert(data.RESULT_MSG);
            return;
          }
            self.itemsLF = [];
            self.itemsLF = data;
            // self.itemsLF.forEach(item => item.PK = item.projectName)
            //Edit by jo 07022022 for change PK to projectID
            self.itemsLF.forEach(item => item.PK = item.projectID)
            self.hot.loadData(self.itemsLF);
      })
  },
  methods: {
    getData(){
        var self = this;
        if (self.hot == null) self.genExcel();
        ProjectService.getProjects()
          .then((response) => {
              var data = response.data
                self.itemsLF = [];
                self.itemsLF = data;
                // self.itemsLF.forEach(item => item.PK = item.projectName)
                //Edit by jo 07022022 for change PK to projectID
                self.itemsLF.forEach(item => item.PK = item.projectID)
                self.hot.loadData(self.itemsLF);
          })
    },
    dateToIso(date) {
      if (date.indexOf("T") != -1) return date;
      var dateString = date; // Oct 23

      var dateParts = dateString.split("/");

      // month is 0-based, that's why we need dataParts[1] - 1
      var dateObject = new Date(
        +dateParts[2],
        dateParts[1] - 1,
        +dateParts[0],
        7,
        0,
        0,
        0
      );

      return dateObject.toISOString();
    },
    isoToDate(isoDate) {
      var date = new Date(isoDate);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var dt = date.getDate();

      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) month = "0" + month;
      return dt + "/" + month + "/" + year;
    },
    genExcel() {
      // Handsontable options
      var self = this;
      var AddNew = true;
      var irows = self.itemsLF.length;
      var iclms = self.clmsLF;
      var _clmReadonly = self.clmReadOnly;
      var option = {
        data: self.itemsLF, //output,
        startRows: irows,
        startCols: iclms,
        // minRows: 5,
        minCols: self.clmsLF.length,
        // width: _width,
        maxRows: AddNew == true ? Infinity : irows,
        maxCols: self.clmsLF.length,
        rowHeaders: true,
        colHeaders: self.clmshLF,
        columns: self.clmsLF,
        minSpareRows: AddNew == true ? 5 : 0,
        contextMenu: ["row_above", "row_below", "remove_row"],
        renderAllRows: false,
        colWidths: self.clmsw,
        // beforeKeyDown: function (e) {
        //   //Add by jo 02012022 for input only numeric on Quanity column (index of 3)
        //   var clm = this.getSelected()[1];
        //   if (clm == 2) {
        //     // var reg = /^\d+$/;
        //     // var reg = /\d+/g;
        //     var reg = /^[0-9]*\.?[0-9]*$/;
        //     if (!reg.test(e.key)) {
        //       e.preventDefault();
        //     }
        //   }
        // },
        beforeRemoveRow: function (index, amount) {
          if (confirm("Do you want to delete rows?") == false) return false;
          var jsonRemove = [];
          var iIndex = index;
          for (var i = 0; i < amount; i++) {
            var element = self.itemsLF[iIndex];
            jsonRemove.push({PK:element.PK});
            iIndex++;
          }
          self.removeRow(jsonRemove)
          // self.$emit('removerow', jsonRemove)
        },
        afterChange: function (change) {
          if (change != undefined) {
            self.afterChange(change);
            // change.forEach(function (element) {
            //   if (element[0] != undefined) {
            //     self.afterChange(change);
            //   }
            // }, this);
          }
        },
        cells: function (row, col, prop) {
          var cellProperties = {};
          if (_clmReadonly == undefined) _clmReadonly = [];
          var found = _clmReadonly.find(function (element) {
            return element == prop;
          });
          if (found != undefined) {
            if (self.itemsLF[row] != undefined) {
              cellProperties.className = "myrowreadonly";
              cellProperties.readOnly = true;
              // if (self.$props.items[row].PK != undefined && self.$props.items[row].PK != '')
              //     cellProperties.readOnly = true
              // else
              //     cellProperties.readOnly = false
            }
          }
          //Add by jo 08112020 for set row  color every 4 rows
          if (self.itemsLF[row] != undefined) {
            if (
              self.itemsLF[row].IS_READONLY != null ||
              self.itemsLF[row].IS_READONLY != undefined
            ) {
              if (self.itemsLF[row].IS_READONLY == "T") {
                cellProperties.readOnly = true;
                // if (cellProperties.readOnly == true)
                if (found != undefined)
                  cellProperties.className = "myrowreadonly";
                else cellProperties.className = "myrowdata";
              } else {
                if (cellProperties.readOnly == true)
                  cellProperties.className = "myreadonly";
              }
            }
          }
          //End of Add by jo 08112020 for set row  color every 4 rows
          if (col % 3 === 0) cellProperties.className = "htRight";
          if (col % 1 === 0) cellProperties.className = "htLeft";
          if (col % 0 === 0) cellProperties.className = "htLeft";
          if (col % 2 === 0) cellProperties.className = "htLeft";
          return cellProperties;
        },
        afterValidate: function (isValid, value, row) {
          if (
            row >=
            self.hot.countRows() - self.hot.getSettings().minSpareRows
          ) {
            return true;
          }
        },
        // ,
      };

      // Instantiate
      document.getElementById("divExcelProject").innerHTML = "";
      // eslint-disable-next-line no-undef
      var ht = new Handsontable(
        document.getElementById("divExcelProject"),
        option
      );
      self.hot = ht;
    },
    afterChange(d) {
      var self = this;
      console.log(d);
      var uniqueItems = d
        .map((item) => item[0])
        .filter((value, index, self) => self.indexOf(value) === index);
      uniqueItems.forEach((element) => {
        console.log(self.itemsLF[element].PK);
      });
      uniqueItems.forEach(changeItem => {
          var index = changeItem
          var saveItem = self.itemsLF[index]
          saveItem.index = index
          if(self.saveItems.filter(a => a.index == index).length == 0)
            self.saveItems.push(saveItem)
      })
    },
    getCurrentDate() {
      let currentDate = new Date();
      let cDay = currentDate.getDate();
      let cMonth = currentDate.getMonth() + 1;
      let cYear = currentDate.getFullYear();
      return cDay + "/" + cMonth + "/" + cYear;
    },
    removeRow(d){
        var self = this;
        self.onProcess = true;
        self.saveMsg = "Deleting..."
        ProjectService.removeData(d).then(response => {
            console.log(response.data)
            var data = response.data
            self.saveMsg = data.RESULT_MSG
            setTimeout(() => self.onProcess = false,3000)
        })
    },
    submitProject() {
      var self = this;
      self.onProcess = true;
      console.log(self.saveItems)
      self.saveMsg = 'Saving...'
      ProjectService.saveProjectMaster(self.saveItems).then(response => {
          var data = response.data;
          self.saveMsg = data.RESULT_MSG;
          setTimeout(()=> {
              self.onProcess = false
              self.saveItems = []
              self.getData()
            },3000)
      })
    
      return;
    },
  },
};
</script>

<style scoped>
.input-group-append {
  cursor: pointer;
}
.container {
  margin-top: 60px;
}
</style>

<style scoped>
::v-deep .f-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .f-modal-content {
  display: flex !important;
  flex-direction: column !important;
  margin: 0 1rem !important;
  padding: 1rem !important;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  font-size: 1.5rem;
  font-weight: 700;
}
</style>

<style scoped>
.dark-mode div::v-deep .f-modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>