import { createRouter, createWebHashHistory } from 'vue-router'
import InputDataManage from '../components/InputDataManage.vue'
// import MenuList from '../components/MenuList.vue'
import InputForm from '../views/InputForm.vue'
import UserHistoryReport from '../views/UserHistoryReport.vue'
import UserTransferReport from '../views/UserTransferReport.vue'
import AdminHistoryReport from '../views/AdminHistoryReport.vue'
import AdminTransferReport from '../views/AdminTransferReport.vue'
import HelpForm from '../views/HelpForm.vue'
import SowForm from '../views/SowForm.vue'
import SowSetup from '../views/SowSetup.vue'
import ProjectMasterSetup from '../views/ProjectMasterSetup.vue'
import ProjectDetailSetup from '../views/ProjectDetailSetup.vue'
import Home from '../components/Home.vue'
import Login from '../components/Login.vue'
import Register from '../components/Register.vue'
import ChangePwd from '../components/ChangePwd.vue'
import EmployeeSetup from '../views/EmployeeSetup.vue'
import UserSetup from '../views/UserSetup.vue'
import BankSetup from '../views/BankSetup.vue'
import SowList from '../views/SowList.vue'
import AdminSowList from '../views/AdminSowList.vue'
import AdminSowForm from '../views/AdminSowForm.vue'
//Lazy-loaded
const Profile = () => import("../components/Profile.vue")
const BoardAdmin = () => import("../components/BoardAdmin.vue")
const BoardModerator = () => import("../components/BoardModerator.vue")
const BoardUser = () => import("../components/BoardUser.vue")

const routes = [
    // {
    //     path: '/',
    //     name: 'MenuList',
    //     component: MenuList
    // },
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/home',
        component: Home
    },
    {
        path: '/inputData',
        name: 'InputDataManage',
        component: InputDataManage
    },{
        path: '/inputForm',
        name: 'InputForm',
        component: InputForm
    },{
        path: '/userHistoryReport',
        name: 'UserHistoryReport',
        component: UserHistoryReport
    },{
        path: '/userTransferReport',
        name: 'UserTransferReport',
        component: UserTransferReport
    },{
        path: '/sowForm/:id',
        name: 'SowForm',
        component: SowForm
    },{

        path: '/adminSowForm/:id/:consult/:fullname',
        name: 'AdminSowForm',
        component: AdminSowForm
    },{
        path: '/sowSetup',
        name: 'SowSetup',
        component: SowSetup
    },{
        path: '/sowList',
        name: 'SowList',
        component: SowList
    },{
        path: '/adminSowList',
        name: 'AdminSowList',
        component: AdminSowList
    },{
        path: '/projectMasterSetup',
        name: 'ProjectMasterSetup',
        component: ProjectMasterSetup
    },{
        path: '/projectDetailSetup',
        name: 'ProjectDetailSetup',
        component: ProjectDetailSetup
    },{
        path: '/employeeSetup',
        name: 'EmployeeSetup',
        component: EmployeeSetup
    },{
        path: '/userSetup',
        name: 'UserSetup',
        component: UserSetup
    },{
        path: '/bankSetup',
        name: 'BankSetup',
        component: BankSetup
    },{
        path: '/login',
        name: 'Login',
        component: Login
    },{
        path: '/changePwd',
        name: 'ChangePwd',
        component: ChangePwd
    },{
        path:'/adminHistoryReport',
        name:'AdminHistoryReport',
        component: AdminHistoryReport,
    },{
        path:'/adminTransferReport',
        name:'AdminTransferReport',
        component: AdminTransferReport,
    },{
        path:'/help',
        name:'HelpForm',
        component: HelpForm
    },{
        path: '/register',
        name: 'Register',
        component: Register
    },{
        path: '/profile',
        name: 'profile',
        // lazy-loaded
        component: Profile
    },{
        path: '/admin',
        name: 'admin',
        // lazy-loaded
        component: BoardAdmin
    },{
        path: '/mod',
        name: 'moderator',
        // lazy-loaded
        component: BoardModerator
    },{
        path: '/user',
        name: 'user',
        // lazy-loaded
        component: BoardUser
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router