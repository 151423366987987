<template>
<div>
  <section class="container">
    <div class="row mb-3">
      <div class="col-12" style="text-align: left">
        <div class="row mb-3">
          <div class="col-4">
            <router-link to="/admin" class="btn btn-warning text-left"
              >Back to main menu</router-link
            >
          </div>
          <div class="col-6">
            <h2 class="py-2">Employee Information</h2>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="container-fluid">
    <div class="excel" style="padding-top: 10px">
      <div id="divExcelProject" class="hot"></div>
    </div>
    <div style="padding-top: 10px">
      <button type="button" class="btn btn-primary" @click="submitProject">
        Submit
      </button>
    </div>
  <vue-final-modal v-model="onProcess" class="f-modal-container" content-class="f-modal-content">
      <span class="modal__title">{{saveMsg}}</span>
  </vue-final-modal>
  </section>
</div>
</template>
<script>
const emptyValidator = function (value, callback) {
  if (!value || 0 === value.length) {
    callback(false);
  } else {
    callback(true);
  }
  // callback(/^\s*$/.test(value) ? false : true);
};
import EmployeeService from "../services/employee.service";
export default {
  name: "EmployeeSetup",
  data() {
    return {
      hot: null,
      clmsLF: [
        {
          data: "FirstName",
          required: true,
          validator: emptyValidator,
        },
        {
          data: "MiddleName",
          //   required: true,
          //   validator: emptyValidator,
        },
        {
          data: "LastName",
          // ,type: 'numeric'
          required: true,
          validator: emptyValidator,
        },
        {
          data: "Suffix",
          // ,type: 'numeric'
          //   required: true,
          //   validator: emptyValidator,
        },
        {
          data: "Company",
          // ,type: 'numeric'
          //   required: true,
          //   type: "date",
          //   validator: emptyValidator,
        },
        {
          data: "MobilePhone",
          // ,type: 'numeric'
          //   required: true,
          //   type: "date",
          //   validator: emptyValidator,
        },
        {
          data: "Email",
          // ,type: 'numeric'
          //   required: true,
          //   validator: emptyValidator,
        },
        {
          data: "EmailType",
          // ,type: 'numeric'
          //   required: true,
          //   validator: emptyValidator,
        },
        {
          data: "EmailDisplayName",
          // ,type: 'numeric'
          //   required: true,
          //   validator: emptyValidator,
        },
        {
          data: "Bank",
        },
        {
          data: "Account",
        },
        {
          data: "BranchCode",
        },
        {
          data: "BankCode",
        },
      ],
      clmshLF: [
        "First Name",
        "Middle<br>Name",
        "Last Name",
        "Siffix",
        "Company",
        "Mobile<br>Phone",
        "Email",
        "Email<br>Type",
        "Email Display Name",
        "Bank",
        "Account",
        "Branch<br>Code",
        "Bank<br>Code",
      ],
      clmsw: [120, 80, 100, 50, 120, 100, 200, 60, 200, 80, 100, 60, 60],
      itemsLF: [
        {
          FirstName: "",
          MiddleName: "",
          LastName: "",
          Suffix: "",
          Company: "",
          MobilePhone: "",
          Email: "",
          EmailType: "",
          EmailDisplayName: "",
          Bank: "",
          Account: "",
          BranchCode: "",
          BankCode: "",
        },
      ],
      clmReadOnly: [],
      onProcess: false,
      saveItems: [],
      saveMsg: ''
    };
  },
  props: {
    userid: String,
  },
  mounted() {
    var self = this;
    if (self.hot == null) self.genExcel();
    else alert("Hello World");
    EmployeeService.getData()
      .then((response) => response.json())
      .then((data) => {
        self.itemsLF = [];
        self.itemsLF = data;
        self.hot.loadData(self.itemsLF);
      });
    // ProjectService.getAllData().then(response => response.json())
    // .then(data => {
    //     self.itemsLF = []
    //     data.forEach(element => {
    //     //  element.effFrom = self.isoToDate(element.effFrom)
    //     //  element.effTo = self.isoToDate(element.effTo)
    //      element.effFrom = DateService.isoToDate(element.effFrom)
    //      element.effTo = DateService.isoToDate(element.effTo)
    //      self.itemsLF.push({
    //        FirstName : element.wbsNumber,
    //        MiddleName : element.projectName,
    //        LastName : element.wbsCode,
    //        Suffix: element.consultant,
    //        Company: element.effFrom,
    //        MobilePhone: element.effTo,
    //        Email: element.methodHcfs,
    //        EmailType: element.methodLoi,
    //        EmailDisplayName: element.serviceDescription
    //      })
    //     });
    //   self.hot.loadData(self.itemsLF)
    // })
  },
  methods: {
    getData(){
      var self = this;
      if (self.hot == null) self.genExcel();
      EmployeeService.getData()
        .then((response) => response.json())
        .then((data) => {
          self.itemsLF = [];
          self.itemsLF = data;
          self.hot.loadData(self.itemsLF);
        });
    },
    dateToIso(date) {
      if (date.indexOf("T") != -1) return date;
      var dateString = date; // Oct 23

      var dateParts = dateString.split("/");

      // month is 0-based, that's why we need dataParts[1] - 1
      var dateObject = new Date(
        +dateParts[2],
        dateParts[1] - 1,
        +dateParts[0],
        7,
        0,
        0,
        0
      );

      return dateObject.toISOString();
    },
    isoToDate(isoDate) {
      var date = new Date(isoDate);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var dt = date.getDate();

      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) month = "0" + month;
      return dt + "/" + month + "/" + year;
    },
    genExcel() {
      // Handsontable options
      var self = this;
      var AddNew = true;
      var irows = self.itemsLF.length;
      var iclms = self.clmsLF;
      var _clmReadonly = self.clmReadOnly;
      var option = {
        data: self.itemsLF, //output,
        startRows: irows,
        startCols: iclms,
        // minRows: 5,
        minCols: self.clmsLF.length,
        // width: _width,
        maxRows: AddNew == true ? Infinity : irows,
        maxCols: self.clmsLF.length,
        rowHeaders: true,
        colHeaders: self.clmshLF,
        columns: self.clmsLF,
        minSpareRows: AddNew == true ? 5 : 0,
        contextMenu: ["row_above", "row_below", "remove_row"],
        renderAllRows: false,
        colWidths: self.clmsw,
        beforeKeyDown: function (e) {
          //Add by jo 02012022 for input only numeric on Quanity column (index of 3)
          var clm = this.getSelected()[1];
          if (clm == 2) {
            // var reg = /^\d+$/;
            // var reg = /\d+/g;
            var reg = /^[0-9]*\.?[0-9]*$/;
            if (!reg.test(e.key)) {
              e.preventDefault();
            }
          }
        },
        beforeRemoveRow: function (index, amount) {
          if (confirm("Do you want to delete rows?") == false) return false;
          var jsonRemove = [];
          var iIndex = index;
          for (var i = 0; i < amount; i++) {
            var element = self.itemsLF[iIndex];
            jsonRemove.push({PK:element.PK});
            iIndex++;
          }
          self.removeRow(jsonRemove)
          // self.$emit('removerow', jsonRemove)
        },
        afterChange: function (change) {
          if (change != undefined) {
            self.afterChange(change);
            // change.forEach(function (element) {
            //   if (element[0] != undefined) {
            //     self.afterChange(change);
            //   }
            // }, this);
          }
        },
        cells: function (row, col, prop) {
          var cellProperties = {};
          if (_clmReadonly == undefined) _clmReadonly = [];
          var found = _clmReadonly.find(function (element) {
            return element == prop;
          });
          if (found != undefined) {
            if (self.itemsLF[row] != undefined) {
              cellProperties.className = "myrowreadonly";
              cellProperties.readOnly = true;
              // if (self.$props.items[row].PK != undefined && self.$props.items[row].PK != '')
              //     cellProperties.readOnly = true
              // else
              //     cellProperties.readOnly = false
            }
          }
          //Add by jo 08112020 for set row  color every 4 rows
          if (self.itemsLF[row] != undefined) {
            if (
              self.itemsLF[row].IS_READONLY != null ||
              self.itemsLF[row].IS_READONLY != undefined
            ) {
              if (self.itemsLF[row].IS_READONLY == "T") {
                cellProperties.readOnly = true;
                // if (cellProperties.readOnly == true)
                if (found != undefined)
                  cellProperties.className = "myrowreadonly";
                else cellProperties.className = "myrowdata";
              } else {
                if (cellProperties.readOnly == true)
                  cellProperties.className = "myreadonly";
              }
            }
          }
          //End of Add by jo 08112020 for set row  color every 4 rows
          if (col % 3 === 0) cellProperties.className = "htRight";
          return cellProperties;
        },
        afterValidate: function (isValid, value, row) {
          if (
            row >=
            self.hot.countRows() - self.hot.getSettings().minSpareRows
          ) {
            return true;
          }
        },
        // ,
      };

      // Instantiate
      document.getElementById("divExcelProject").innerHTML = "";
      // eslint-disable-next-line no-undef
      var ht = new Handsontable(
        document.getElementById("divExcelProject"),
        option
      );
      self.hot = ht;
    },
    afterChange(d) {
      var self = this;
      console.log(d);
      var uniqueItems = d
        .map((item) => item[0])
        .filter((value, index, self) => self.indexOf(value) === index);
      uniqueItems.forEach((element) => {
        console.log(self.itemsLF[element].PK);
      });
      uniqueItems.forEach(changeItem => {
          var index = changeItem
          var saveItem = self.itemsLF[index]
          saveItem.index = index
          if(self.saveItems.filter(a => a.index == index).length == 0)
            self.saveItems.push(saveItem)
      })
      // if(d != undefined){
      //    var self = this
      //    d.forEach(function(element){
      //         if(element[1].toLowerCase() == 'store_location'){
      //             var aresult = self.filterByClm(self.storeLocationItems,'LOCATION',element[3])
      //             self.itemsLF[element[0]].STORE_DESCRIPTION = aresult[0].DESCRIPTION
      //         }
      //    });
      //    self.hot.loadData(self.itemsLF)
      // }
    },
    getCurrentDate() {
      let currentDate = new Date();
      let cDay = currentDate.getDate();
      let cMonth = currentDate.getMonth() + 1;
      let cYear = currentDate.getFullYear();
      return cDay + "/" + cMonth + "/" + cYear;
    },
    removeRow(d){
        var self = this;
        self.onProcess = true;
        self.saveMsg = "Deleting..."
        EmployeeService.removeData(d).then(response => {
            console.log(response.data)
            var data = response.data
            self.saveMsg = data.RESULT_MSG
            setTimeout(() => self.onProcess = false,3000)
        })
    },
    submitProject() {
      var self = this;
      self.onProcess = true;
      console.log(self.saveItems)
      self.saveMsg = 'Saving...'
      EmployeeService.saveEmployee(self.saveItems).then(response => {
          console.log(response.data)
          var data = response.data;
          self.saveMsg = data.RESULT_MSG;
          setTimeout(()=> {
            self.onProcess = false
            self.saveItems = []
            self.getData()
          },3000)
      })
    
      return;
    //   var aSave = [];
    //   var bEmptyData = false;
    //   console.log(bEmptyData);
    //   self.itemsLF.forEach((item) => {
    //     if (
    //       item.FirstName == null &&
    //       item.MiddleName == null &&
    //       item.LastName == null &&
    //       item.Suffix == null &&
    //       item.Company == null &&
    //       item.MobilePhone == null &&
    //       item.Email == null &&
    //       item.EmailType == null &&
    //       item.EmailDisplayName == null
    //     )
    //       bEmptyData = true;
    //     else {
    //       aSave.push({
    //         FirstName: item.WbsNumber,
    //         MiddleName: item.MiddleName,
    //         LastName: item.LastName,
    //         Suffix: item.Suffix,
    //         Company: item.Company,
    //         MobilePhone: item.MobilePhone,
    //         Email: item.Email,
    //         EmailType: item.EmailType,
    //         EmailDisplayName: item.EmailDisplayName,
    //       });
    //     }
    //   });
    //   aSave.forEach((element) => {
    //     element.Company = DateService.dateToIso(element.Company);
    //     element.MobilePhone = DateService.dateToIso(element.MobilePhone);
    //     ProjectService.getData(element.FirstName)
    //       .then((response) => {
    //         if (response.ok) return response.json();
    //         else if (response.status === 404)
    //           return Promise.reject("error 404");
    //         else return Promise.reject("some other error:" + response.status);
    //       })
    //       .then((data) => {
    //         console.log("Call put to update");
    //         console.log(data);
    //         ProjectService.updateProjectData(element.FirstName, element).then(
    //           (response) => {
    //             console.log(response.data);
    //           }
    //         );
    //       })
    //       .catch((error) => {
    //         if (error === "error 404") {
    //           ProjectService.addProjectData(element).then((response) => {
    //             console.log(response.data);
    //           });
    //         }
    //       });
    //   });
    },
  },
};
</script>

<style scoped>
.input-group-append {
  cursor: pointer;
}
.container {
  margin-top: 60px;
}
</style>

<style scoped>
::v-deep .f-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .f-modal-content {
  display: flex !important;
  flex-direction: column !important;
  margin: 0 1rem !important;
  padding: 1rem !important;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  font-size: 1.5rem;
  font-weight: 700;
}
</style>

<style scoped>
.dark-mode div::v-deep .f-modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>