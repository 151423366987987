
<template>
    <header class="jumbotron">
      <h3>
        <strong>Change password for {{userName}}</strong>
      </h3>
    </header>
<div class="row" style="padding-top: 10px;">
  <div class="col-3"></div>
  <div class="col-6">
    <div class="card card-container">
      <Form @submit="changePassword" :validation-schema="schema">
        <div class="form-group">
          <label for="password">Old Password</label>
          <Field name="password" type="password" class="form-control" ref="passwordInput"/>
          <ErrorMessage name="password" class="error-feedback text-danger" />
        </div>
        <div class="form-group">
          <label for="newpassword">New Password</label>
          <Field name="newpassword" type="password" class="form-control" />
          <ErrorMessage name="newpassword" class="error-feedback text-danger" />
        </div>
        <div class="form-group">
          <label for="confirmpassword">Confrim New Password</label>
          <Field name="confirmpassword" type="password" class="form-control" />
          <ErrorMessage name="confirmpassword" class="error-feedback text-danger" />
        </div>
        <div class="form-group">
          <button class="btn btn-primary btn-block" :disabled="loading">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span>Change Password</span>
          </button>
        </div>
        <div class="form-group">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
        </div>
      </Form>
    </div>
  </div>
</div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
export default ({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Login',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      password: yup.string().required("Old Password is required!"),
      newpassword: yup.string().required("New Password is required!"),
      confirmpassword: yup.string().oneOf([yup.ref('newpassword'),null],'Password must match').required("Confirm New Password is required!"),
    });
    return {
      loading: false,
      message: "",
      schema,
      userName: "",
    };
  },
  mounted() {
    var self = this
    self.userName = this.$store.state.auth.user.username;
    self.$refs.passwordInput.$el.focus();
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  methods: {
    changePassword(user) {
      var self = this
      user.username = self.userName
      this.loading = true;
      this.$store.dispatch("auth/changePassword", user).then(
        (data) => {
          this.message = data.message;
          this.successful = true;
          this.loading = false;
          setTimeout(() => {
           self.$router.push("/home") 
          }, 3000);
        },
        (error) => {
          console.log(error)
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
});
</script>