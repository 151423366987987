import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    faHome,
    faUser,
    faUserPlus,
    faSignInAlt,
    faSignOutAlt,
    faFileExcel,
    faBarChart,
    faFileSignature,
    faGear,
    faClone,
    faMoneyBillTransfer,
    faKey,
    faBuildingColumns,
    faClose,
    faQuestion,
    faCircleLeft,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faFilePdf,
    faSignature,
} from "@fortawesome/free-solid-svg-icons";
library.add(faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt,faFileExcel,faBarChart,faFileSignature, faGear,faClone,faMoneyBillTransfer,faKey,faBuildingColumns,faClose,faQuestion,faCircleLeft,faArrowDown,faArrowLeft,faArrowRight,faFilePdf, faSignature);
export { FontAwesomeIcon };