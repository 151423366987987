<template>
  <div class="container">
    <section id="secSOW">
      <div class="row mb-3">
        <div class="col-12 col-md-4">
          <router-link v-show="onProcess == false" to="/sowList" class="btn btn-warning text-left"
            ><font-awesome-icon icon="circle-left"></font-awesome-icon> Back to SOW List</router-link
          >
        </div>
        <label class="col-12 col-md-6 text-center"><h3>Statement of Work</h3></label>
      </div>
      <div class="row mb-3">
        <label class="col-12 col-md-3 text-left"
          ><b class="text-left">Project WBS Number:</b></label
        >
        <label class="col-12 col-md-9 text-left lbData">{{wbsCode}}</label>
        <label class="col-12 col-md-3 text-left"
          ><b class="text-left">Project Name:</b></label
        >
        <label class="col-12 col-md-9 text-left lbData">{{projectName}}</label>
        <label class="col-12 col-md-3 text-left"
          ><b class="text-left">Project Leader:</b></label
        >
        <label class="col-12 col-md-9 text-left lbData">{{projectLeader}}</label>
      </div>
      <div class="row mb-3">
        <label class="col-12 text-left"
          >{{fullname}} (“Consultant”) and P Solution Plus Co., Ltd. (“PSP”)
          enter this SOW effectively
        </label>
        <label class="col-12 text-left"
          >from <b class="text-left lbData">{{effFrom}}</b> to
          <b class="text-left lbData">{{effTo}}</b></label
        >
      </div>
      <div class="row mb-3">
        <label class="col-1 text-left">1.</label>
        <label class="col-11 text-left"
          ><u class="font-weight-bold">Services Description</u> (collectively
          the “<b>Services</b>”)</label
        >
        <label class="col-1 text-left"></label>
        <label class="col-11 text-left lbData"
          >{{serviceDesc}}</label
        >
        <label class="col-1 text-left"></label>
        <label class="col-11 text-left"
          >Consultant acknowledges and agrees that no additional work may be
          performed unless agreed to in writing in advance by an authorized
          representative of PSP. Upon the request of PSP, Consultant agrees to
          promptly provide PSP with a written report detailing Consultant’s
          activities or proposed activities for the period of time
          requested.</label
        >
      </div>
      <div class="row mb-3">
        <label class="col-1 text-left">2.</label>
        <label class="col-11 text-left"
          ><u class="font-weight-bold">Deliverables</u></label
        >
        <label class="col-1 text-left"></label>
        <label class="col-11 text-left"
          >Qualilative</label
        >
        <label class="col-1 text-left"></label>
        <label class="col-11 text-left lbData"
          ><li v-for="deliverableItem in deliverableItems" :key="deliverableItem.seq">
            {{deliverableItem.description}}
          </li></label
        >
        
          <label v-show="deliverableAcceptantItems.length > 0" class="col-1 text-left"></label>
          <label v-show="deliverableAcceptantItems.length > 0" class="col-11 text-left"
            >Acceptance Criteria:</label
          >
          <label v-show="deliverableAcceptantItems.length > 0" class="col-1 text-left"></label>
          <label v-show="deliverableAcceptantItems.length > 0" class="col-11 text-left"
            >Qualilative</label
          >
          <label v-show="deliverableAcceptantItems.length > 0" class="col-1 text-left"></label>
          <label v-show="deliverableAcceptantItems.length > 0" class="col-11 text-left lbData"
            ><li v-show="deliverableAcceptantItems.length > 0" v-for="deliverableAcceptantItem in deliverableAcceptantItems" :key="deliverableAcceptantItem.seq">
              {{deliverableAcceptantItem.description}}
            </li></label
          >
        <label class="col-1 text-left"></label>
        <label class="col-11 text-left"
          >In any case, Consultant represents and warrants that all the
          Information, Services and Deliverables (i) will be performed in a good
          and professional manner with due care and diligence in compliance with
          the professional standards generally applicable in similar industry,
          (ii) will be accurate and true in every material aspect and will not
          contain any material misstatement of fact or omit to state a material
          fact or any fact necessary to make the statement contained therein not
          misleading.</label
        >
      </div>
      <div class="row mb-3">
        <label class="col-1 text-left">3.</label>
        <label class="col-11 text-left"
          ><u class="font-weight-bold">Methodology: </u></label
        >
        <label class="col-1 text-left"></label>
        <label class="col-11 text-left" >
          <label>Where applicable, Consultant will perform all Services and produce
          all deliverables in accordance with the following methodology{{(hcfs == '' && loi == '') ? ' indicated by the client': ':'}}
</label>
        </label >
        <label v-show="hcfs != ''" class="col-1 text-left"></label>
        <label v-show="hcfs != ''" class="col-11 text-left lbData" >
          <li v-show="hcfs != ''">{{hcfs}}</li></label
        >
        <label v-show="loi != ''" class="col-1 text-left"></label>
        <label v-show="loi != ''" class="col-11 text-left lbData" >
          <li v-show="loi != ''">{{loi}}</li></label
        >
      </div>
     <div class="pagebreak"> </div> 
      <div class="row mb-3">
        <label class="col-1 text-left">4.</label>
        <label class="col-11 text-left"
          ><u class="font-weight-bold"
            >Services Milestones and Payment Schedule.</u
          ></label
        >
        <label class="col-1 text-left"></label>
        <label class="col-11 text-left"
          >The Services will be performed or provided in accordance with the
          following milestones and at the rates set forth below. Invoices shall
          be issued in accordance with the invoicing schedule specified
          below.</label
        >
        <label class="col-1 text-left"></label>
        <label class="col-11 text-left"
          ><u
            >Timetable for the Delivery of the Services/Deliverables Fee</u
          ></label
        >
        <label class="col-1 text-left"></label>
        <div class="col-11">
          <table class="table table-bordered tb-saw">
            <!-- <thead>
              <tr>
                <th>Service Description</th>
                <th>Amount (THB)</th>
              </tr>
            </thead> -->
            <tbody>
              <tr>
                <td>Service Description</td>
                <td>Amount (THB)</td>
              </tr>
              <tr v-for="serviceItem in serviceItems" :key="serviceItem.seq">
                <td scope="row" class="text-left">{{serviceItem.description}}</td>
                <td class="text-center">{{serviceItem.amount}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-1"></div>
        <label class="col-11 text-left"
          ><u>Payment Schedule</u> The final payment of the Fees will be made upon the satisfaction of the Services and Deliverables.</label
        >
        <div class="col-1"></div>
        <label class="col-11 text-left">
            PSP will administer payments. Any modification to the Fees must be agreed to in writing in advance by an authorized representative of PSP. PSP will electronically transfer the above to Consultant bank account.
        </label>
      </div>
      <div class="row mb-3">
        <label class="col-1 text-left">5.</label>
        <label class="col-11 text-left"
          ><u class="font-weight-bold">Term</u>. This SOW shall take effect as of the SOW Effective Date and shall be completed when all of the Services and Deliverables as specified herein have been delivered in full compliance with the Services and Deliverables descriptions set out herein, unless this SOW is terminated earlier or is extended by agreement between the parties in writing.</label
        >
      </div>
      <div class="row mb-3">
        <label class="col-1 text-left">6.</label>
        <label class="col-11 text-left"
          ><u class="font-weight-bold">Adverse Event Reports</u>.</label
        >
        <label class="col-1 text-left"></label>
        <label class="col-11 text-left"
          >Adverse event reporting process requires that Consultant shall collect and submit any adverse events experienced while performing the Services 24 (twenty-four) hours by following the local reporting processes for the market where the adverse event was identified.</label
        >
        <label class="col-1 text-left"></label>
        <label class="col-11 text-left"
          >Consultant has the obligation to strictly follow the Adverse Event Reporting High Level Guidelines as listed below:</label
        >
        <div class="col-12" style="display:table">
          <label class="col-1 text-left" style="display:table-cell"></label>
          <div class="col-4 text-left border border-dark ml-3" style="display:table-cell;min-width: 400px;">
            <label class="text-left">Consultant to align with client in the project kick-off meeting to understand Adverse Event Requirements:</label>
            <li class="text-left">What to report</li>
            <li class="text-left">Client brands</li>
            <li class="text-left">How to report (AE forms, contacts, detail process etc.)</li>
          </div>
          <div class="col-1 align-middle" style="display:table-cell">
            <font-awesome-icon icon="arrow-right" />
          </div>
          <div class="col-4 text-left border border-dark ml-3 align-middle" style="display:table-cell;min-width: 400px;">
            <label class="text-left">Consultant to align with client on the detail process to report the Adverse Events</label>
          </div>
        </div>
        <div class="col-12" style="display:table">
          <label class="col-1 text-left" style="display:table-cell"></label>
          <div class="col-4 text-left" style="display:table-cell;">
          </div>
          <div class="col-1 align-middle" style="display:table-cell">
          </div>
          <div class="col-4 text-left" style="display:table-cell;min-width: 400px;">
            <label class="col-6"></label>
            <font-awesome-icon class="text-left" icon="arrow-down" />
          </div>
        </div>
        <div class="col-12" style="display:table;">
          <label class="col-1 text-left" style="display:table-cell"></label>
          <div class="col-4 text-left border border-dark ml-3" style="display:table-cell;min-width: 400px;">
            <label class="text-left">In case of any missing Adverse Events, Consultant is responsible for escalation, root-cause analysis, corrective, and preventative actions.</label>
          </div>
          <div class="col-1 align-middle" style="display:table-cell">
            <font-awesome-icon icon="arrow-left" />
          </div>
          <div class="col-4 text-left border border-dark ml-3 align-middle" style="display:table-cell;min-width: 400px;">
            <label class="text-left">Consultant to complete reconciliation form and send to client at regular basis.</label>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <label class="col-1 text-left">7.</label>
        <label class="col-11 text-left"
          ><u class="font-weight-bold">Payment of Honorarium and Incentive for Market Research</u>
        </label>
        <label class="col-1"></label>
        <label class="col-11 text-left">Where applicable to the services provided by the Consultant under this SOW, payment of cash, physical voucher and physical gift to participants of market research, is prohibited whereas payment of a cash equivalent with a digital tracking mechanism, such as an ATM code, bank transfer or E-voucher, is acceptable. Non-compliance of the above by the Consultant shall be deemed a material breach and the SOW shall be terminated henceforth. The Consultant shall not be entitled to any payment for non-compliant work.</label>
      </div>
      <div class="row mb-3">
        <label class="col-12 text-left">
          <b>IN WITNESS WHEREOF</b>, the authorized representatives of the parties hereto have executed this SOW on the SOW Effective Date. 
        </label>
      </div>
      <div class="row mb-3">
        <label class="col-12 text-left lbTitle">CONSULTANT</label>
        <label class="col-3 col-md-1 text-left font-weight-bold mt-3">Name:</label>
        <label class="col-9 col-md-11 text-left font-weight-bold mt-3">{{fullname}}</label>
        <label class="col-3 col-md-1 text-left font-weight-bold">Title:</label>
        <label class="col-9 col-md-11 text-left font-weight-bold">Fieldworker</label>
        <label class="col-1 text-left font-weight-bold">Signature:</label>
        <img :v-show="consultAck == 'T'" ref="imgSignature"/>
        <div class="col-10" :v-if="consultAck != 'T'">
          <VueSignaturePad 
            :customStyle="{ border: '1px solid black',display: consultAck == 'T' ? 'none': '' }"
            id="signature"
            width="100%"
            height="500px"
            ref="signaturePad"
          />
        </div>
      </div>
      <div v-show="pspAck == 'T'" class="row mb-3">
        <label class="col-12 text-left lbTitle">P SOLUTION PLUS CO., LTD.</label>
        <label class="col-3 col-md-1 text-left font-weight-bold mt-3">Name:</label>
        <label class="col-9 col-md-11 text-left font-weight-bold mt-3">Akekalak Sukantha</label>
        <label class="col-3 col-md-1 text-left font-weight-bold">Title:</label>
        <label class="col-9 col-md-11 text-left font-weight-bold">Managing Director</label>
        <label class="col-1 text-left font-weight-bold">Signature:</label>
        <img :v-show="pspAck == 'T'" ref="imgSignaturePsp"/>
        <div class="col-10" :v-if="pspAck != 'T'">
        </div>
      </div>
    </section>
  <button v-show="onProcess == false" type="button" class="btn btn-secondary mb-3" @click="printXXX">
    <font-awesome-icon icon="file-pdf"/> 
    Print
  </button>
  <button v-show="onProcess == false && consultAck != 'T'" type="button" class="btn btn-primary mb-3 ml-3" @click="acknowledge">
    <font-awesome-icon icon="signature"/> 
    Acknowledge
  </button>
  <button v-show="onProcess == false && consultAck != 'T'" type="button" class="btn btn-primary mb-3 ml-3" @click="undo">
    Undo
  </button>
  <!-- <button v-show="onProcess == false" type="button" onclick="printJS('docs/printjs.pdf')">
    Print PDF
 </button> -->
  </div>
</template>
<script>
import {ref, onMounted, provide, computed} from "vue";
import { useRoute } from "vue-router";
import store from '@/store'
import ProjectService from '../services/project.service';
import DeliverableService from '../services/deliverable.service';
import ServiceService from '../services/service.service'; 
import DateService from '../services/date.service';
import DeliverableAcceptantService from '../services/deliverable.acceptant.service';
import projectConsultService from '@/services/project.consult.service';
// import printJS from 'print-js';
export default {
  name: "SowForm",
  setup() {
    const route = useRoute();

    const projectID = ref("");
    // const projectID = computed(() => route.params.id);
    const projectSelected = computed(() => route.params.id);
    const fullname = computed(() => route.params.fullname);
    const wbsNumber = ref("");
    const projectName = ref("");
    const wbsCode = ref("");
    const projectLeader = ref("");
    const effFrom = ref("");
    const effTo = ref("");
    const hcfs = ref("");
    const loi = ref("");
    const serviceDesc = ref("");
    const deliverableItems = ref([]);
    const deliverableAcceptantItems = ref([]);
    const serviceItems = ref([]);
    const onProcess = ref(false);
    const userName = ref("");
    const adminRole = ref(false);
    const signaturePad = ref(null);
    const imgSignature = ref(null);
    const imgSignaturePsp = ref(null);
    const consultSignature = ref("");
    const consultAck = ref("");
    const pspSignature = ref("");
    const pspAck = ref("");
    provide('store',store)
    onMounted(() => {
      userName.value = store.state.auth.user.username;
      if(store.state.auth.user && store.state.auth.user['roles'])
        adminRole.value = store.state.auth.user['roles'].includes('admin');
      // imgSignature.value.src = dataSign; 
      // signaturePad.value.fromDataURL(dataSign)
    })
    function clearData() {
      projectID.value = ref("");
      projectSelected.value = ref("");
      wbsNumber.value = ref("");
      wbsCode.value = ref("");
      projectLeader.value = ref("");
      effFrom.value = ref("");
      effTo.value = ref("");
      hcfs.value = ref("");
      loi.value = ref("");
      serviceDesc.value = ref("");
      deliverableItems.value = ref([]);
      deliverableAcceptantItems.value = ref([]);
      serviceItems.value = ref([]);
    }
    function printXXX(){
      // var text = document.getElementById("secSOW").innerHTML;
      // var printWindow = window.open('', '', 'height=400,width=800');
      // printWindow.document.write('<html><head><title>Html to PDF</title>');
      // printWindow.document.write('</head><body >');
      // printWindow.document.write(text);
      // printWindow.document.write('</body></html>');
      // printWindow.document.close();
      // printWindow.print();
      onProcess.value = true;
      setTimeout(()=> {
        window.print();
        onProcess.value = false;
      },300)
      
      // printJS({
      //   printable:'secSOW',
      //   type: 'html',
      //   targetStyles: ['*'],
      //   header:''
      // })
    }
    function undo(){
      signaturePad.value.undoSignature();
    }
    function acknowledge(){
      const {isEmpty, data } = signaturePad.value.saveSignature();
      if(isEmpty == true){
        alert('Please kindly sign Signature!');
        return;
      }
      onProcess.value = true;
      projectConsultService.consultAcknowledge(data,projectID.value,userName.value).then(response => {
        var data = response.data;
        alert(data.RESULT_MSG)
        setTimeout(() => {
          onProcess.value = false
          getData()
        })
      })
    }
    function pspApprove(){
      const {isEmpty, data } = signaturePad.value.saveSignature();
      if(isEmpty == true){
        alert('Please kindly sign Signature!');
        return;
      }
      onProcess.value = true;
      projectConsultService.pspAcknowledge(data,projectID.value,userName.value).then(response => {
        var data = response.data;
        alert(data.RESULT_MSG)
        setTimeout(() => {
          onProcess.value = false
          getData()
        })
      })
    }
    function getDeliverable() {
      DeliverableService.getData(projectID.value).then((response) => {
        var data = response.data;
        deliverableItems.value = [];
        deliverableItems.value = data;
      });
    }
    function getDeliverableAcceptant() {
      DeliverableAcceptantService.getData(projectID.value).then((response) => {
        var data = response.data;
        deliverableAcceptantItems.value = [];
        deliverableAcceptantItems.value = data;
      });
    }
    function getService() {
      ServiceService.getData(projectID.value).then((response) => {
        var data = response.data;
        serviceItems.value = [];
        serviceItems.value = data;
      });
    }
    function getAcknowledgeStatus() {
      projectConsultService.getAcknowledgeStatus(projectID.value,userName.value).then((response) => {
        var data = response.data;
        consultSignature.value = data[0].consultSignature;
        consultAck.value = data[0].consultAck;
        if(consultAck.value == 'T')
          imgSignature.value.src = consultSignature.value; 
        pspSignature.value = data[0].pspSignature;
        pspAck.value = data[0].pspAck;
        if(pspAck.value == 'T')
          imgSignaturePsp.value.src = pspSignature.value; 
      });
    }
    function getData() {
      ProjectService.getData(projectSelected.value).then((response) => {
        var data = response.data;
        if (data.RESULT_CODE == "99") {
          alert(data.RESULT_MSG);
          return;
        }
        if(data.length > 0){
            projectID.value = data[0].projectID;
            wbsNumber.value = data[0].wbsNumber;
            projectName.value = data[0].projectName;
            wbsCode.value = data[0].wbsCode;
            projectLeader.value = data[0].projectLeader;
            if(data[0].effFrom != null)
                effFrom.value = DateService.toLocalDate(new Date(data[0].effFrom));
            else
                effFrom.value = null;
            
            if(data[0].effTo != null)
                effTo.value = DateService.toLocalDate(new Date(data[0].effTo));
            else
                effTo.value = null;
            hcfs.value = data[0].hcfs == null ? '' : data[0].hcfs;
            loi.value = data[0].loi == null ? '' : data[0].loi;
            serviceDesc.value = data[0].serviceDesc;
            getDeliverable();
            getDeliverableAcceptant();
            getService();
            getAcknowledgeStatus();
        }
        else clearData()
      });
    }
    getData();
    return {
      projectSelected,
      wbsNumber,
      projectName,
      wbsCode,
      projectLeader,
      effFrom,
      effTo,
      hcfs,
      loi,
      serviceDesc,
      clearData,
      getData,
      projectID,
      deliverableItems,
      deliverableAcceptantItems,
      serviceItems,
      printXXX,
      onProcess,
      userName,
      adminRole,
      undo,
      signaturePad,
      acknowledge,
      pspApprove,
      imgSignature,
      consultSignature,
      consultAck,
      pspSignature,
      pspAck,
      imgSignaturePsp,
      getAcknowledgeStatus,
      fullname
    };
  },
};
</script>
<style scoped>
.container {
  margin-top: 60px;
}
.lbData {
  color: #2cb3e5;
  font-weight: bold;
}
.tb-saw th {
  font-weight: bold;
  text-align: center;
}
.lbTitle{
  color:#4472c4;
  font-weight: bold;
}
@media print {
    .pagebreak {
        clear: both;
        page-break-after: always;
    }
}
</style>