import axios from 'axios';
// const API_URL = "http://localhost:7111/";
// const API_URL = "https://www.psolutionplus.com/";
import ApiService from '../services/api.service';
const API_URL = ApiService.getApiUrl();
class DataService {
    addData(data){
        return axios.post(API_URL + 'saveData',{
            project: data.project,
            firstName: data.firstName,
            lastName: data.lastName,
            bankAccount: data.bankAccount,
            promptpay: data.promptpay,
            gwallet: data.gwallet,
            bankName: data.bankName,
            branchName: data.branchName,
            branchCode: data.branchCode,
            tel: data.tel,
            email: data.email,
            transferDate: data.transferDate,
            amount: data.amount,
            createdBy: data.createdBy,
        });
    }
    getData(userName){
       return fetch(API_URL + 'dataToday/' + userName,{
           createdBy: userName
       }); 
    }
    isOverDate(){
       return fetch(API_URL + 'isOverDate'); 
    }
    isTransferOverDate(transferDate){
        return axios.post(API_URL + 'isTransferOverDate',{
            transferDate:transferDate
        });
    }
    historyReport(data){
        return axios.post(API_URL + 'historyReport',{
            createdBy: data.createdBy,
            fromDate: data.fromDate,
            toDate: data.toDate
        });
    }
    historyReportAll(data){
        return axios.post(API_URL + 'historyReportAll',{
            createdBy: data.createdBy,
            fromDate: data.fromDate,
            toDate: data.toDate
        });
    }
    transferReport(data){
        return axios.post(API_URL + 'transferReport',{
            createdBy: data.createdBy,
            fromDate: data.fromDate,
            toDate: data.toDate
        });
    }
    transferReportAll(data){
        return axios.post(API_URL + 'transferReportAll',{
            createdBy: data.createdBy,
            fromDate: data.fromDate,
            toDate: data.toDate,
        });
    }
    updateData(data){
        return axios.put(API_URL + 'data/' + data.Rectid,{
            recid: data.Recid,
            project: data.project,
            firstName: data.firstName,
            lastName: data.lastName,
            bankAccount: data.bankAccount,
            promptpay: data.promptpay,
            gwallet: data.gwallet,
            bankName: data.bankName,
            branchName: data.branchName,
            branchCode: data.branchCode,
            tel: data.tel,
            email: data.email,
            transferDate: data.transferDate,
            amount: data.amount,
            createdBy: data.createdBy,
        })
    }
    saveTransfer(data){
        return axios.put(API_URL + 'saveTransfer/' + data.Recid,{
            recid: data.Recid,
            transfered: data.transfered,
            transferedBy: data.transferedBy,
        })
    }
    removeData(data){
        return axios.delete(API_URL + 'data/' + data.Recid,{
            recid: data.Recid
        })
    }
}
export default new DataService();