import axios from 'axios';
// const API_URL = 'http://192.168.1.109:7221/';
// const API_URL = "http://localhost:7111/";
// const API_URL = "https://www.psolutionplus.com/";
import ApiService from '../services/api.service';
const API_URL = ApiService.getApiUrl();
class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'login', {
        UserName: user.username,
        Password: user.password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }
  changePassword(user) {
    return axios
      .post(API_URL + 'changePassword', {
        UserName: user.username,
        Password: user.password,
        NewPassword: user.newpassword
      })
  }
  logout() {
    localStorage.removeItem('user');
  }
  register(user) {
    return axios.post(API_URL + 'Register', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }
}
export default new AuthService();