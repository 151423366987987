/* eslint-disable */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import jQuery from 'jquery'
import VueSignaturePad from 'vue-signature-pad'
import { FontAwesomeIcon } from './plugins/font-awesome'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal';
// import printJS from 'print-js'
const luxon = require("luxon");
global.$ = jQuery
global.luxon = luxon
// import Handsondtable from '../dist/js/vendor/handsontable.full'
// global.Handsondtable = Handsondtable
createApp(App)
.use(router)
.use(store)
.use(VueSignaturePad)
.component("font-awesome-icon", FontAwesomeIcon)
.component("vue-final-modal", VueFinalModal)
.component("modals-container", ModalsContainer)
.mount('#app')
