class DateService{
    toLocalDate(dateStr) {
      const date = new Date(dateStr);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var dt = date.getDate();

      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) month = "0" + month;
      return month + "/" + dt + "/" + year;
    }
    dateToIso(date){
      if(date.indexOf('T') != -1)
        return date;
      var dateString = date; // Oct 23

      var dateParts = dateString.split("/");

      // month is 0-based, that's why we need dataParts[1] - 1
      var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0],7,0,0,0); 

      return dateObject.toISOString();

    }
    isoToDate(isoDate){
      var date = new Date(isoDate);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var dt = date.getDate();

      if(dt<10){
        dt = '0' + dt;
      }
      if(month < 10)
        month = '0' + month;
      return dt + '/' + month + '/' + year
    }
}
export default new DateService();