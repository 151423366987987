<template>
  <section class="container">
    <div class="row">
      <div class="col-12 col-md-4 text-projectName">
        <router-link to="/user" class="btn btn-warning text-left"
          ><font-awesome-icon icon="circle-left"></font-awesome-icon> Back to main menu</router-link
        >
      </div>
      <div class="col-12 col-md-6">
        <h2 class="py-2">Input bank data</h2>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-1"></div>
      <div class="col-11">
        <!-- <form class="row mb-3"> -->
        <Form @submit="submitInput" :validation-schema="schema">
          <!-- <div class="row">
                <label for="name" class="col-2 col-form-label text-left">Project:</label>
                <div class="col-10">
                    <Field name="name" type="text" class="form-control"/>
                    <ErrorMessage name="name" class="error-feedback"/>
                </div> 
            </div> -->
          <div class="row" style="padding-top: 5px">
            <label for="project" class="col-2 col-form-label text-left"
              >Project:</label
            >
            <div class="col-12 col-md-10 text-left">
              <select style="height:37px" class="form-select form-select-lg" ref="projectinput" aria-label=".form-select-lg example" v-model="iproject" aria-describedby="helpProject">
                <option v-for="(item, index) in projectItems" :key="index" :value="item.projectName">{{item.projectName}}</option>
              </select>
              <small v-show="iproject == ''"
                id="helpProject"
                class="form-text text-left text-warning"
              >Please select project!</small>
              <!-- <Field name="project" type="text" class="form-control" ref="projectinput" v-model="iproject" /> -->
              <!-- <ErrorMessage name="project" class="error-feedback" /> -->
            </div>
          </div>
          <div class="row" style="padding-top: 5px;">
            <label for="name" class="col-2 col-form-label text-left"
              >Name:</label
            >
            <div class="col-12 col-md-4">
              <!-- <input type="text" id="name" class="form-control" v-model="name"/> -->
              <Field
                v-model="iname"
                name="name"
                type="text"
                class="form-control"
                aria-describedby="helpName"
                ref="nameinput"
              />
              <small id="helpName" class="form-text text-muted text-left"
                >First Name</small
              >
              <ErrorMessage name="name" class="error-feedback" />
            </div>
            <div class="col-12 col-md-4">
                  <!-- <input type="text" id="name" class="form-control" v-model="name"/> -->
                  <Field
                    v-model="ilastname"
                    name="lastname"
                    type="text"
                    class="form-control"
                    aria-describedby="helpLastName"
                    ref="lastnameinput"
                  />
                  <small
                    id="helpLastName"
                    class="form-text text-muted text-left"
                    >Last Name</small
                  >
                  <ErrorMessage name="lastname" class="error-feedback" />
            </div>
          </div>
          <div class="row" style="padding-top: 5px">
            <label for="bankAccount" class="col-2 col-form-label text-left"
              >Account</label
            >
            <div class="col-12 col-md-4" style="text-align:left;">
              <select style="height:37px" class="form-select form-select-lg" aria-label=".form-select-lg example" v-model="bankSelected" aria-describedby="helpBank">
                <option  value=""></option>
                <option v-for="(item, index) in bankItems" :key="index" :value="item.bankCode">{{item.bankName}}</option>
              </select>
              <small id="helpBank" class="form-text text-muted text-left"
                >Bank Name</small>
              <!-- <input type="text" id="account" class="form-control" v-model="bankAccount"/> -->
            </div>
            <div class="col-12  col-md-4">
              <!-- <Field v-model="ibankname" name="bankName" type="text" class="form-control"
               ref="banknameinput" aria-describedby="helpBank"/>
              <ErrorMessage name="bankName" class="error-feedback" /> -->
              <Field :readonly="bankSelected == ''" v-model="ibankaccount" name="bankAccount" type="number" :maxlength="accountMax" 
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" 
               class="form-control" ref="bankAccountInput" aria-describedby="helpAccount"/>
              <small id="helpAccount" class="form-text text-muted text-left"
                >Account Number {{accountWarning}}</small>
              <ErrorMessage name="bankAccount" class="error-feedback" />
            </div>
          </div>
          <div class="row" style="padding-top: 5px">
            <label for="promptpay" class="col-4 col-md-2 col-form-label text-left"
              >Prompt Pay</label
            >
            <div class="col-12 col-md-10">
              <Field v-model="ipromptpay" name="promptpay" type="text" class="form-control" ref="promptpayinput" aria-describedby="helpPromptpay"/>
              <ErrorMessage name="promptpay" class="error-feedback" />
              <small
                id="helpPromptpay"
                class="form-text text-left text-warning"
              >Prompt Pay or G-Wallet data</small>
            </div>
          </div>
          <!-- <div class="row" style="padding-top: 5px">
            <label for="gwallet" class="col-2 col-form-label text-left"
              >G-Wallet</label
            >
            <div class="col-10">
              <Field v-model="igwallet" name="gwallet" type="text" class="form-control" ref="gwalletinput" />
              <ErrorMessage name="gwallet" class="error-feedback" />
            </div>
          </div> -->
          <div class="row" style="padding-top: 5px">
            <label for="date" class="col-6 col-md-2 col-form-label text-left"
              >Transfer Date</label
            >
            <div class="col-12 col-md-10">
              <div class="input-group date" id="datepicker">
                <!-- <input type="text" class="form-control" id="date"/> -->
                <datepicker
                  id="date"
                  :calendar-button="true"
                  caledar-button-icon="fa fa-calendar"
                  class="form-control"
                  v-model="transferDate"
                  :min-date="minDate"
                  :lowerLimit="lowerLimit"
                  aria-describedby="helpTransferDate"
                ></datepicker>
                <!-- <span class="input-group-append">
                      <span class="input-group-text bg-light d-block">
                        <i class="fa fa-calendar"></i>
                      </span>
                    </span> -->
              </div>
              <small
                id="helpTransferDate"
                class="form-text text-left text-warning"
              >Current Date at {{currentDate}} , System will transfer transection before 22:00</small>
            </div>
          </div>
          <div v-show="0==1" class="row" style="padding-top: 5px">
            <label for="branchName" class="col-2 col-form-label text-left"
              >Branch Name</label
            >
            <div class="col-10">
              <!-- <input type="text" id="branchName" class="form-control" v-model="branchName"/> -->
              <Field v-model="ibranchname" name="branchName" type="text" class="form-control" ref="branchnameinput" />
              <ErrorMessage name="branchName" class="error-feedback" />
            </div>
          </div>
          <div v-show="0==1" class="row" style="padding-top: 5px">
            <label for="branchCode" class="col-2 col-form-label text-left"
              >Branch Code</label
            >
            <div class="col-10">
              <!-- <input type="text" id="branchCode" class="form-control" v-model="branch"/> -->
              <Field v-model="ibranchcode" name="branchCode" type="text" class="form-control" ref="branchcodeinput" />
              <ErrorMessage name="branchCode" class="error-feedback" />
            </div>
          </div>
          <div class="row" style="padding-top: 5px">
            <label for="tel" class="col-2 col-form-label text-left">SMS</label>
            <div class="col-12 col-md-10">
              <!-- <input type="text" id="tel" class="form-control" v-model="tel"/> -->
              <Field v-model="itel" name="tel" type="text" maxlength="32" class="form-control" ref="telinput"
              @keypress="onlyNumber"
             aria-describedby="helpTel"  />
              <ErrorMessage name="tel" class="error-feedback" />
              <small v-show="0==1"
                id="helpTel"
                class="form-text text-left text-warning"
              >SMS can input 3 smss, EX: 0891292244,0891202136,0853603733</small>
              <small v-if="smsMsg != ''" class="form-text text-left text-danger">{{smsMsg}}</small>
            </div>
          </div>
          <div class="row" style="padding-top: 5px">
            <label for="branchName" class="col-2 col-form-label text-left"
              >Email</label
            >
            <div class="col-12 col-md-10">
              <Field v-model="iemail" name="email" type="text" class="form-control" ref="emailinput" />
              <ErrorMessage name="email" class="error-feedback" />
            </div>
          </div>
          <div class="row" style="padding-top: 5px">
            <label for="amount" class="col-2 col-form-label text-left"
              >Amount:</label
            >
            <div class="col-12 col-md-10">
              <!-- <input type="number" id="amount" class="form-control" v-model="amount"/> -->
              <Field v-model="iamount" name="amount" type="number" class="form-control" ref="amountinput" />
              <ErrorMessage name="amount" class="error-feedback" />
            </div>
          </div>
          <div class="row" style="padding-top: 5px">
            <div class="col-9" style="text-align: right">
              <!-- <button class="btn btn-primary text-right" @click.prevent="submitInput">Submit</button> -->
              <button class="btn btn-primary text-right mr-1">Submit</button>
              <button class="btn btn-secondary text-right" @click.prevent="clearInput">Clear</button>
            </div>
            <div class="col-3 text-center">
                <div
                  v-if="message"
                  class="alert"
                  :class="successful ? 'alert-success' : 'alert-danger'"
                >
                  {{message}}
                </div>
            </div>
          </div>
        </Form>
        <!-- </form> -->
      </div>
    </div>
  </section>
  <section class="container-fluid">
    <div class="row mb-3">
      <div class="col-12">
        <h4 class="text-left">History Data at {{ currentDate }}:</h4>
        <div ref="table"></div>
      </div>
    </div>
  </section>

  <!-- <vue-final-modal v-model="onEdit" class="modal-container" content-class="modal-content">
      <span class="modal__title">Hello Hello hed eYunk u nor eMar</span>
  </vue-final-modal> -->
  <!-- <vue-final-modal v-model="onEdit" classes="modal-container" content-class="modal-content">
      <button class="modal__close" @click="onEdit = false">
        <font-awesome-icon icon="close"></font-awesome-icon>
      </button>
      <span class="modal__title">Hello, vue-final-modal</span>
      <div class="modal__content">
        <edit-form ></edit-form>
      </div>
    </vue-final-modal> -->
</template>
<script>
import { ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import Datepicker from "vue3-datepicker";
import DataService from "../services/data.service";
import DateService from "../services/date.service";
import dateService from '../services/date.service';
import BankService from '../services/bank.service';
import ProjectService from '../services/project.service';
//custom date formatter
// eslint-disable-next-line no-unused-vars
var dateFormatter = function (cell, formatterParams) {
  var value = cell.getValue();

  if (value) {
    // eslint-disable-next-line no-undef
    value = moment(value, "YYYY/MM/DD").format("MM/DD/YYYY");
  }

  return value;
};
// eslint-disable-next-line no-unused-vars
var dateTimeFormatter = function (cell, formatterParams) {
  var value = cell.getValue();

  if (value) {
    // eslint-disable-next-line no-undef
    value = moment(value, "YYYY/MM/DD HH:mm:ss").format("MM/DD/YYYY HH:mm:ss");
  }

  return value;
};

// eslint-disable-next-line no-unused-vars
var duplicateIcon = function(cell, formatterParams, onRendered){
  // return "<font-awesome-icon icon='home' />";
  // return '<label>Hello World</label>' 
  // return '<svg class="svg-inline--fa fa-house" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="house" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path class="" fill="currentColor" d="M575.8 255.5C575.8 273.5 560.8 287.6 543.8 287.6H511.8L512.5 447.7C512.5 450.5 512.3 453.1 512 455.8V472C512 494.1 494.1 512 472 512H456C454.9 512 453.8 511.1 452.7 511.9C451.3 511.1 449.9 512 448.5 512H392C369.9 512 352 494.1 352 472V384C352 366.3 337.7 352 320 352H256C238.3 352 224 366.3 224 384V472C224 494.1 206.1 512 184 512H128.1C126.6 512 125.1 511.9 123.6 511.8C122.4 511.9 121.2 512 120 512H104C81.91 512 64 494.1 64 472V360C64 359.1 64.03 358.1 64.09 357.2V287.6H32.05C14.02 287.6 0 273.5 0 255.5C0 246.5 3.004 238.5 10.01 231.5L266.4 8.016C273.4 1.002 281.4 0 288.4 0C295.4 0 303.4 2.004 309.5 7.014L564.8 231.5C572.8 238.5 576.9 246.5 575.8 255.5L575.8 255.5z"></path></svg>'
  return '<svg class="svg-inline--fa fa-clone" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="clone" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="color: rgb(71, 143, 124);"><path class="" fill="currentColor" d="M0 224C0 188.7 28.65 160 64 160H128V288C128 341 170.1 384 224 384H352V448C352 483.3 323.3 512 288 512H64C28.65 512 0 483.3 0 448V224zM224 352C188.7 352 160 323.3 160 288V64C160 28.65 188.7 0 224 0H448C483.3 0 512 28.65 512 64V288C512 323.3 483.3 352 448 352H224z"></path></svg>'
};
export default {
  name: "InputForm",
  components: {
    Datepicker,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      project: yup.string(),
      name: yup.string().required("Name is required!"),
      lastname: yup.string(),
      amount: yup.string().required("Amount is required!"),
      bankAccount: yup.string(),
      promptpay: yup.string().max(40),
      gwallet: yup.string().max(40),
      bankName: yup.string(),
      // branchCode: yup.string().required("Branch Code is required!"),
      // branchName: yup.string().required("Branch Name is required!"),
      branchCode: yup.string(),
      branchName: yup.string(),
      tel: yup.string().required("SMS is required!"),
      email: yup.string().email(),
    });

    return {
      // name: '',
      // amount: '',
      iproject:'',
      iname:'',
      ilastname:'',
      iamount:'',
      ibankaccount:'',
      ipromptpay:'',
      igwallet:'',
      ibankname:'',
      ibranchcode:'',
      ibranchname:'',
      itel:'',
      iemail:'',
      transferDate: ref(new Date()),
      lowerLimit: ref(new Date()),
      // bankAccount: '',
      // bankName:'',
      // branch:'',
      // tel:'',
      userName: "",
      inputItems: [],
      table: null,
      iconC: "red",
      iconW: "45px",
      iconH: "45px",
      currentDate: "",
      schema,
      minDate: "2022-03-30",
      maxDate: "2022-04-08",
      message: '',
      successful: true,
      smsMsg:'',
      bankItems:[],
      bankSelected: '',
      bankSelectedItem: '',
      accountWarning: '',
      accountMin:10,
      accountMax:10,
      projectItems: [],
    };
  },
  mounted() {
    var self = this;
    // self.$refs.projectinput.$el.focus();
    self.$refs.projectinput.focus();
    self.clearInput();
    self.userName = this.$store.state.auth.user.username;
    // eslint-disable-next-line no-undef
    var day = moment().toDate();
    // eslint-disable-next-line no-undef
    self.currentDate = moment(day).format("MM/DD/YYYY");
    //define data array
    // var tabledata = [
    //     {id:1, name:"Oli Bob", progress:12, gender:"male", rating:1, col:"red", dob:"19/02/1984", car:1},
    //     {id:2, name:"Mary May", progress:1, gender:"female", rating:2, col:"blue", dob:"14/05/1982", car:true},
    //     {id:3, name:"Christine Lobowski", progress:42, gender:"female", rating:0, col:"green", dob:"22/05/1982", car:"true"},
    //     {id:4, name:"Brendon Philips", progress:100, gender:"male", rating:1, col:"orange", dob:"01/08/1980"},
    //     {id:5, name:"Margret Marmajuke", progress:16, gender:"female", rating:5, col:"yellow", dob:"31/01/1999"},
    //     {id:6, name:"Frank Harbours", progress:38, gender:"male", rating:4, col:"red", dob:"12/05/1966", car:1},
    // ];

    //initialize table
    // // eslint-disable-next-line no-undef
    // var table = new Tabulator("#example-table", {
    //     reactiveData:true,
    //     data:self.inputItems, //assign data to table
    //     autoColumns:true, //create columns from data field names
    // });
    // self.table = table

    //define data
    var tabledata = [];

    self.inputItems = tabledata;
    //Build Tabulator
    // eslint-disable-next-line no-undef
    // var table = new Tabulator("#example-table", {
    // eslint-disable-next-line no-undef
    self.table = new Tabulator(self.$refs.table, {
      height: "311px",
      layout: "fitColumns",
      reactiveData: true, //turn on data reactivity
      data: tabledata, //load data into table
      columns: [
        {
          formatter: duplicateIcon,
          width: 40,
          hozAlign: "center",
          cellClick: function (e, cell) {
            if (confirm("Are you sure you want to duplicate this entry to input form?")) {
              var data = cell.getRow().getData()
              self.project = data.project 
              self.firstName = data.firstName 
            }
          },
        },
        {
          formatter: "buttonCross",
          width: 40,
          hozAlign: "center",
          cellClick: function (e, cell) {
            if (confirm("Are you sure you want to delete this entry?")) {
              var recid = cell.getRow().getData().recid;
              DataService
                .removeData({
                  Recid: recid,
                })
                .then((response) => {
                  var result = response.data;
                  if (result != undefined) self.getData();
                });
              cell.getRow().delete();
            }
          },
        },
        { title: "First Name", field: "firstname", sorter: "string", width: 100 },
        { title: "Last Name", field: "lastname", sorter: "string", width: 100 },
        { title: "Account", field: "bankAccount", sorter: "string" },
        {
          title: "Date",
          field: "transferDate",
          sorter: "date",
          formatter: dateFormatter,
        },
        { title: "Bank Name", field: "bankName", sorter: "string" },
        // { title: "Branch Name", field: "branchName", sorter: "string" },
        // { title: "Branch Code", field: "branchCode", sorter: "string" },
        { title: "Tel.", field: "tel", sorter: "string" },
        { title: "Amount", field: "amount", sorter: "number" },
        { title: "Created Date", field: "createdDate", sorter: "string" },
      ],
    });
    self.getData();
    self.isOverDate();
    self.isTranferOverDate()
    self.getBankData()
    self.getProjectData()
  },
  methods: {
    getProjectData(){
      var self = this;
      self.projectItems = []
      ProjectService.getProjects()
      .then((response) => {
        var data = response.data;
        if(data.RESULT_CODE == '99'){
          alert(data.RESULT_MSG)
          return;
        }
        self.projectItems = data;
      })
    },
    getBankData(){
        var self = this;
        self.bankItems = []
        BankService.getBanks()
          .then((response) => {
              var data = response.data
              self.bankItems = data
          })
    },
    isOverDate(){
      var self = this
      //to check date mush more then 22:00 if more then then default transfer date is next date
      DataService.isOverDate().then(response => response.json())
      .then(data => {
        self.lowerLimit = ref(new Date())
        self.lowerLimit.setDate(self.lowerLimit.getDate() + 1);
        if(data.isOverDate == true){
          self.lowerLimit.setDate(self.lowerLimit.getDate() + 1);
        }
        self.transferDate = self.lowerLimit
      })
    },
    isTranferOverDate(){
      var self = this
      //to check date mush more then 22:00 if more then then default transfer date is next date
      var localDate = dateService.toLocalDate(self.transferDate)
      DataService.isTransferOverDate(localDate).then(response => {
        var result = response.data
        if(result.isOverDate == true){
          self.lowerLimit = ref(new Date())
          self.lowerLimit.setDate(self.lowerLimit.getDate() + 2);
          self.transferDate = self.lowerLimit
        }
        //add by jo 08282022 for check is transfer date is current date then add defaut transfer date +1 days
        else if(result.isCurrentDate == true){
          self.lowerLimit = ref(new Date())
          self.lowerLimit.setDate(self.lowerLimit.getDate() + 1);
          self.transferDate = self.lowerLimit
        }
        //end of add by jo 08282022 for check is transfer date is current date then add defaut transfer date +1 days
      })
    },
    onlyNumber($event){
      let keycode = ($event.keyCode ? $event.keyCode : $event.which);
      if((keycode < 48 || keycode > 57) && keycode != 44) {
        //44 is comma
        $event.preventDefault();
      }
    },
    isRequired(value){
      return value ? true: 'This field is required'
    },
    getData() {
      var self = this;
      self.inputItems = [];
      DataService.getData(self.userName)
        .then((response) => response.json())
        .then((data) => {
          var result = data;
          // console.log(data)
          data.forEach(element => {
            // console.log(element.transferDate) 
            // console.log(new Date(element.transferDate)) 
            element.transferDate = new Date(element.transferDate)
            // console.log(element.createdDate) 
            // console.log(new Date(element.createdDate)) 
            element.createdDate = new Date(element.createdDate)
          });
          self.inputItems = result;
          // result.forEach(element => {
          //     var filterd = self.inputItems.filter(a => a.recid == element.recid)
          //     if(filterd.length == 0)
          //         self.inputItems.push(element)
          // });
          // self.table.replaceData(self.inputItems)
          // console.log(self.inputItems)
          // self.table.setData(self.i)
        });
    },
    bindTable(d) {
      var self = this;
      //define data
      var tabledata = d;

      self.inputItems = tabledata;
      //Build Tabulator
      // eslint-disable-next-line no-undef
      // var table = new Tabulator("#example-table", {
      // eslint-disable-next-line no-undef
      self.table = new Tabulator(self.$refs.table, {
        height: "311px",
        layout: "fitColumns",
        reactiveData: true, //turn on data reactivity
        data: tabledata, //load data into table
        columns: [
          {
            formatter: duplicateIcon,
            width: 40,
            hozAlign: "center",
            cellClick: function (e, cell) {
            if (confirm("Are you sure you want to duplicate this entry to input form?")) {
              var data = cell.getRow().getData()
              self.setData(data)
            }
            },
          },
        {
          formatter: "buttonCross",
          width: 40,
          hozAlign: "center",
          cellClick: function (e, cell) {
            if (confirm("Are you sure you want to delete this entry?")) {
              var recid = cell.getRow().getData().recid;
              DataService
                .removeData({
                  Recid: recid,
                })
                .then((response) => {
                  var result = response.data;
                  if (result != undefined) self.getData();
                });
              cell.getRow().delete();
            }
          },
        },
        { title: "Project", field: "project", sorter: "string", width: 100 },
        { title: "First Name", field: "firstname", sorter: "string", width: 100 },
        { title: "Last Name", field: "lastname", sorter: "string", width: 100 },
        { title: "Account", field: "bankAccount", sorter: "string" },
        { title: "Prompt Pay", field: "promptpay", sorter: "string" },
        // { title: "G-Wallet", field: "gwallet", sorter: "string" },
        {
          title: "Date",
          field: "transferDate",
          sorter: "date",
          formatter: dateFormatter,
        },
        { title: "Bank Name", field: "bankName", sorter: "string" },
        // { title: "Branch Name", field: "branchName", sorter: "string" },
        // { title: "Branch Code", field: "branchCode", sorter: "string" },
        { title: "SMS", field: "tel", sorter: "string" },
        { title: "Email", field: "email", sorter: "string" },
        { title: "Amount", field: "amount", sorter: "number" },
        { title: "Created Date", field: "createdDate", sorter: "datetime", formatter: dateTimeFormatter },
        ],
      });
    },
    setData(values){
      var self = this;
      
      self.iproject = values.project
      self.iname = values.firstname // self.schema.fields.lastName = values.lastname
      self.ilastname = values.lastname
      self.ibankaccount = values.bankAccount == undefined ? '' : values.bankAccount
      self.ipromptpay = values.promptpay == undefined ? '' : values.promptpay
      self.igwallet = values.gwallet == undefined ? '' : values.gwallet
      self.ibankname = values.bankName
      self.ibranchname = values.branchName
      self.ibranchcode = values.branchCode
      self.itel = values.tel
      self.iemail = values.email
      self.iamount = values.amount
      var bankItem = self.bankItems.filter(y => y.bankName == values.bankName)
      if(bankItem.length > 0)
        self.bankSelected = bankItem[0].bankCode 
      //add by jo 05282022 for check transfer date must more than current date
      self.isTranferOverDate();
      //end of add by jo 05282022 for check transfer date must more than current date

    },
    submitInput(values) {
      var self = this;
      values.transferDate = self.transferDate;
      values.CreatedBy = self.userName;
      if(self.iproject == ''){
        self.$refs.projectinput.focus()
        return
      }
      else{
        if(self.iproject == undefined){
          self.$refs.projectinput.focus()
          return;
        }
        values.project = self.iproject;
      }
      if(self.bankSelectedItem.bankName != undefined)
        values.bankName = self.bankSelectedItem.bankName;
      else
        values.bankName = ''
      // var data = {Name:self.name,Amount:self.amount,TransferDate:self.date,account:self.account,bankName:self.bankName,branch:self.branch,tel:self.tel}
      var data = {
        project: values.project,
        firstName: values.name,
        lastName: values.lastname,
        bankAccount: values.bankAccount == undefined ? '' : values.bankAccount,
        promptpay: values.promptpay == undefined ? '' : values.promptpay,
        gwallet: values.gwallet == undefined ? '' : values.gwallet,
        bankName: values.bankName,
        branchName: values.branchName,
        branchCode: values.branchCode,
        tel: values.tel,
        email: values.email,
        transferDate: DateService.toLocalDate(values.transferDate),
        amount: values.amount,
        createdBy: values.CreatedBy,
      };
      // if(data.bankAccount == '' && data.promptpay == '' && data.gwallet == ''){
      //   alert('Please input one of Bank Account, Prompt Pay and G-Wallet')
      //   self.$refs.bankAccountInput.$el.focus();
      //   return;
      // }
      if(data.bankAccount == '' && data.promptpay == '' ){
        alert('Please input one of Bank Account, Prompt Pay or G-Wallet')
        self.$refs.bankAccountInput.$el.focus();
        return;
      }
      else if(data.bankAccount != '' && data.bankName == ''){
        alert('Please input Bank Name')
        self.$refs.banknameinput.$el.focus();
        return;
      }
      else if(data.bankAccount != '' && data.bankName != ''){
        if(self.accountMin == self.accountMax){
          if(data.bankAccount.length != self.accountMax){
            alert('Bank Account must equal ' + self.accountMax + ' digits')
            self.$refs.bankAccountInput.$el.focus();
            return;
          }
        }
        else if(self.accountMin != self.accountMax){
          var isBetween = data.bankAccount.length >= self.accountMin && data.bankAccount.length <= self.accountMax
          if(isBetween == false){
            alert('Bank Account must between ' + self.accountMin + ' to ' + self.accountMax + ' digits')
            self.$refs.bankAccountInput.$el.focus();
            return;
          }
        }
      }
      if(self.validateSMS() == false){
        self.$refs.telinput.$el.focus();
        return false;
      }
      
      var localDate = dateService.toLocalDate(self.transferDate)
      DataService.isTransferOverDate(localDate).then(response => {
        var result = response.data
        if(result.isOverDate == true){
          alert("Can't select Transection date at " + localDate + ' because currenet time more than 22:00')
          self.lowerLimit = ref(new Date())
          self.lowerLimit.setDate(self.lowerLimit.getDate() + 1);
          self.transferDate = self.lowerLimit
        }
        //Add by jo 05082022 for check transfer date must > current date
        else if(result.isCurrentDate == true){
          alert("Can't select Transection date at " + localDate)
          self.lowerLimit = ref(new Date())
          self.lowerLimit.setDate(self.lowerLimit.getDate() + 1);
          self.transferDate = self.lowerLimit
        }
        //End of Add by jo 05082022 for check transfer date must > current date
        else{
          //If transfer date is normally will call addData to seve to database
          DataService.addData(data).then((response) => {
            var result = response.data;
            if(result.RESULT_CODE != 0)
              alert(result.RESULT_MSG)
            self.successful = result.RESULT_CODE == 0 ? true : false
            self.message = result.RESULT_MSG
            setTimeout(() => self.message = '' ,5000)
            self.getData();
            self.clearInput();
          });
        }
      })
    },
    clearInput() {
      var self = this;
      self.iproject = '';
      self.iname = "";
      self.ilastname = "";
      self.ibankaccount = "";
      self.ipromptpay = "";
      self.igwallet = "";
      self.ibankname = "";
      self.ibranchname = "";
      self.ibranchcode = "";
      self.itel = "";
      self.iemail = "";
      //Edit by jo 05282022 for transerfer date much more than current date
      self.lowerLimit = ref(new Date())
      self.lowerLimit.setDate(self.lowerLimit.getDate() + 1);
      self.transferDate = self.lowerLimit
      //End of Edit by jo 05282022 for transerfer date much more than current date
      self.iamount = "";
      self.bankSelected = ''
      self.bankSelectedItem = ''
      self.accountMin = 10 
      self.accountMax = 10
      self.accountWarning = ''
      // self.$refs.projectinput.$el.focus();
      self.$refs.projectinput.focus();
      //Add by jo for check logic on tranfer date much more than current date
      self.isOverDate();
      self.isTranferOverDate()
      //End of Add by jo for check logic on tranfer date much more than current date
    },
    validateSMS(){
      var self = this;
      var aSms = self.itel.split(',')
      var msg = ''
      if(aSms.length > 3)
        msg = "SMS much not more than 3 smss"
      aSms.forEach((sms,index) => {
       if(sms.length != 10) 
        msg += msg == '' ? `SMS ${index + 1} much have 10 digits` : ` , SMS ${index + 1} much have 10 digits`
      });
      self.smsMsg = msg
      return msg == '' ? true : false;
    }
  },
  watch: {
    inputItems: {
      handler: function (newData) {
        // this.table.replaceData(newData)
        this.bindTable(newData);
      },
      deep: true,
    },
    itel(value){
      var aSms = value.split(',')
      var msg = ''
      if(aSms.length > 3){
        msg = "SMS much not more than 3 smss"
      }
      aSms.forEach((sms,index) => {
       if(sms.length != 10) 
        msg += msg == '' ? `SMS ${index + 1} much have 10 digits` : ` , SMS ${index + 1} much have 10 digits`
      });
      this.smsMsg = msg;
    },
    transferDate(){
      this.isTranferOverDate(true)
    },
    bankSelected(){
      var self = this
      var filterd = self.bankItems.filter(item => item.bankCode == self.bankSelected) 
      if(filterd.length == 0)
        self.bankSelectedItem = ''
      else
        self.bankSelectedItem = filterd[0];
      if(self.bankSelected == ''){
        self.ibankaccount = ''
        self.accountWarning = ''
        return self
      }
      if(self.bankSelectedItem.accountLength == self.bankSelectedItem.accountLengthMax)
      {
        self.accountMin = self.bankSelectedItem.accountLength
        self.accountMax = self.bankSelectedItem.accountLengthMax
        self.accountWarning = ' must equal ' + self.bankSelectedItem.accountLength + ' digits'
      }
      else
      {
        self.accountMin = self.bankSelectedItem.accountLength
        self.accountMax = self.bankSelectedItem.accountLengthMax
        self.accountWarning = ' must between ' + self.bankSelectedItem.accountLength + ' - ' + self.bankSelectedItem.accountLengthMax + ' digits'
      }
    }
  },
};
</script>

<style scoped>
.input-group-append {
  cursor: pointer;
}
.container {
  margin-top: 60px;
}
</style>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  display: flex !important;
  flex-direction: column !important;
  margin: -1 1rem !important;
  padding: 0rem !important;
  border: 0px solid #e2e8f0;
  border-radius: -1.25rem;
  background: #fff;
}
.modal__title {
  font-size: 0.5rem;
  font-weight: 699;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #1d3748;
  background-color: #0a202c;
}
</style>


<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
}
.modal__content {
  flex-grow: 1;
  overflow-y: auto;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>