import axios from "axios";
import apiService from '../services/api.service'
const API_URL = apiService.getApiUrl();
class ProjectService{
    getData(projectName){
        return axios.get(API_URL + "project/" + projectName,{
            projectName: projectName
        })
    }
    getProjects(){
        return axios.get(API_URL + "projects");
    }
    saveProjectMaster(data){
        return axios.post(API_URL + "saveProjectMaster",{
            projects: data,
        });
    }
    saveProject(data){
        return axios.post(API_URL + "saveProject",{
            projects: data,
        });
    }
    removeData(data){
        return axios.post(API_URL + 'projectsRemove',{
            projects: data,
        })
    }
    // Old for call from .Net6 API
    // addProjectData(dataList){
    //     return axios.post(API_URL + 'SaveProject',{
    //         WbsNumber: dataList.WbsNumber,
    //         ProjectName: dataList.ProjectName,
    //         WbsCode: dataList.WbsCode,
    //         Consultant: dataList.Consultant,
    //         EffFrom: dataList.EffFrom,
    //         EffTo: dataList.EffTo,
    //         MethodHcfs: dataList.MethodHcfs,
    //         MethodLoi: dataList.MethodLoi,
    //         ServiceDescription: dataList.ServiceDescription
    //     });
    //     // return axios.post(API_URL + 'SaveProject',dataList);
    // }
    // updateProjectData(wbsNumber,data){
    //     return axios.put(API_URL + 'UpdateProject/' + wbsNumber,{
    //         WbsNumber: wbsNumber,
    //         ProjectName: data.ProjectName,
    //         WbsCode: data.WbsCode,
    //         Consultant: data.Consultant,
    //         EffFrom: data.EffFrom,
    //         EffTo: data.EffTo,
    //         MethodHcfs: data.MethodHcfs,
    //         MethodLoi: data.MethodLoi,
    //         ServiceDescription: data.ServiceDescription
    //     },{
    //         WbsNumber: data.WbsNumber,
    //         ProjectName: data.ProjectName,
    //         WbsCode: data.WbsCode,
    //         Consultant: data.Consultant,
    //         EffFrom: data.EffFrom,
    //         EffTo: data.EffTo,
    //         MethodHcfs: data.MethodHcfs,
    //         MethodLoi: data.MethodLoi,
    //         ServiceDescription: data.ServiceDescription
    //     });
    // }
}
export default new ProjectService();