<template>
  <div class="container">
    <div class="row mb-3">
      <div class="col-12" style="text-align: left">
        <div class="row mb-3">
          <div class="col-6">
            <router-link to="/admin" class="btn btn-warning text-left"
              >Back to main menu</router-link
            >
          </div>
          <div class="col-6"></div>
        </div>
        <div class="row mb-3">
          <h4>Project Information:</h4>
        </div>
        <div class="excel" style="padding-top: 10px">
          <div id="divExcelProject" class="hot"></div>
        </div>
        <div style="padding-top: 10px">
          <button type="button" class="btn btn-primary" @click="submitProject">Submit</button>
        </div>
        <div class="row mb-3" style="padding-top: 5px">
          <div class="col-1"></div>
          <div class="col-5">
            <div class="input-group mb-3">
              <span class="input-group-text">Project WBS Number</span>
              <select id="ProjectWBSNumber" class="form-select">
                <option v-for="(item,index) in cProjectDist" :value="item" :key="index">{{item}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <h4>Deliverable</h4>
        </div>
        <div class="excel" style="padding-top: 10px">
          <div id="divExcelDE" class="hot"></div>
        </div>
        <div style="padding-top: 10px">
          <button type="button" class="btn btn-primary">Submit</button>
        </div>
        <div class="row mb-3" style="margin-top:10px">
          <h4>Service Description</h4>
        </div>
        <div class="excel" style="padding-top: 10px">
          <div id="divExcelSE" class="hot"></div>
        </div>
        <div style="padding-top: 10px">
          <button type="button" class="btn btn-primary">Submit</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const emptyValidator = function (value, callback) {
  if (!value || 0 === value.length) {
    callback(false);
  } else {
    callback(true);
  }
  // callback(/^\s*$/.test(value) ? false : true);
};
import ProjectService from '../services/project.service';
export default {
  name: "SowSetup",
  data() {
    return {
      hot: null,
      clmsLF: [
        {
          data: "WbsNumber",
          required: true,
          validator: emptyValidator,
        },
        {
          data: "ProjectName",
          required: true,
          validator: emptyValidator,
        },
        {
          data: "WbsCode",
          // ,type: 'numeric'
          required: true,
          validator: emptyValidator,
        },
        {
          data: "Consultant",
          // ,type: 'numeric'
          required: true,
          validator: emptyValidator,
        },
        {
          data: "EffFrom",
          // ,type: 'numeric'
          required: true,
          type: "date",
          validator: emptyValidator,
        },
        {
          data: "EffTo",
          // ,type: 'numeric'
          required: true,
          type: "date",
          validator: emptyValidator,
        },
        {
          data: "MethodHcfs",
          // ,type: 'numeric'
          required: true,
          validator: emptyValidator,
        },
        {
          data: "MethodLoi",
          // ,type: 'numeric'
          required: true,
          validator: emptyValidator,
        },
        {
          data: "ServiceDescription",
          // ,type: 'numeric'
          required: true,
          validator: emptyValidator,
        },
      ],
      clmshLF: [
        "Projct WBS Number",
        "Project Name",
        "WBS Code",
        "Consultant",
        "Effectively From",
        "Effectively To",
        "Method HCFs",
        "Method LOI",
        "Service Desciption",
      ],
      clmsw: [150, 150, 100, 150, 120, 120, 200, 200, 200],
      itemsLF: [
        {
          WbsNumber: "",
          ProjectName: "",
          WbsCode: "",
          Consultant: "",
          EffFrom: "",
          EffTo: "",
          MethodHcfs: "",
          MethodLoi: "",
          ServiceDescription: "",
        },
      ],
      clmReadOnly: [],
      hotDE: null,
      clmsDE: [
        {
          data: "DELIVERABLE",
          required: true,
          validator: emptyValidator,
        },
      ],
      clmswDE: [600],
      clmshDE: ["Deliverable Description"],
      itemsDE: [
        {
          DELIVERABLE: "",
        },
      ],
      hotSE: null,
      clmsSE: [
        {
          data: "ServiceDescription",
          required: true,
          validator: emptyValidator,
        },
        {
          data: "AMOUNT",
          required: true,
          validator: emptyValidator,
        },
      ],
      clmswSE: [600, 100],
      clmshSE: ["Service Description", "Amount"],
      itemsSE: [
        {
          ServiceDescription: "",
          AMOUNT: "",
        },
      ],
    };
  },
  props: {
    userid: String,
  },
  mounted() {
    var self = this;
      if (self.hot == null) self.genExcel();
      else alert("Hello World");
      self.genExcelDE();
      self.genExcelSE();
    ProjectService.getAllData().then(response => response.json())
    .then(data => {
        self.itemsLF = []
        data.forEach(element => {
         element.effFrom = self.isoToDate(element.effFrom)
         element.effTo = self.isoToDate(element.effTo)
         self.itemsLF.push({
           WbsNumber : element.wbsNumber,
           ProjectName : element.projectName,
           WbsCode : element.wbsCode,
           Consultant: element.consultant,
           EffFrom: element.effFrom,
           EffTo: element.effTo,
           MethodHcfs: element.methodHcfs,
           MethodLoi: element.methodLoi,
           ServiceDescription: element.serviceDescription
         }) 
        });
      self.hot.loadData(self.itemsLF)
    })
  },
  methods: {
    dateToIso(date){
      if(date.indexOf('T') != -1)
        return date;
      var dateString = date; // Oct 23

      var dateParts = dateString.split("/");

      // month is 0-based, that's why we need dataParts[1] - 1
      var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0],7,0,0,0); 

      return dateObject.toISOString();

    },
    isoToDate(isoDate){
      var date = new Date(isoDate);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var dt = date.getDate();

      if(dt<10){
        dt = '0' + dt;
      }
      if(month < 10)
        month = '0' + month;
      return dt + '/' + month + '/' + year
    },
    genExcel() {
      // Handsontable options
      var self = this;
      var AddNew = true;
      var irows = self.itemsLF.length;
      var iclms = self.clmsLF;
      var _clmReadonly = self.clmReadOnly;
      var option = {
        data: self.itemsLF, //output,
        startRows: irows,
        startCols: iclms,
        // minRows: 5,
        minCols: self.clmsLF.length,
        // width: _width,
        maxRows: AddNew == true ? Infinity : irows,
        maxCols: self.clmsLF.length,
        rowHeaders: true,
        colHeaders: self.clmshLF,
        columns: self.clmsLF,
        minSpareRows: AddNew == true ? 5 : 0,
        contextMenu: ["row_above", "row_below", "remove_row"],
        renderAllRows: false,
        colWidths: self.clmsw,
        beforeKeyDown: function (e) {
          //Add by jo 02012022 for input only numeric on Quanity column (index of 3)
          var clm = this.getSelected()[1];
          if (clm == 2) {
            // var reg = /^\d+$/;
            // var reg = /\d+/g;
            var reg = /^[0-9]*\.?[0-9]*$/;
            if (!reg.test(e.key)) {
              e.preventDefault();
            }
          }
        },
        beforeRemoveRow: function (index, amount) {
          if (self.isValidate == false) {
            if (confirm("Do you want to delete rows?") == false) return false;
          }
          self.isValidate = true;
          var jsonRemove = [];
          var iIndex = index;
          for (var i = 0; i < amount; i++) {
            var element = self.itemsLF[iIndex];
            jsonRemove.push(element);
            iIndex++;
          }
          // self.$emit('removerow', jsonRemove)
        },
        afterChange: function (change) {
          if (change != undefined) {
            change.forEach(function (element) {
              if (element[0] != undefined) {
                self.afterChange(change);
              }
            }, this);
          }
        },
        cells: function (row, col, prop) {
          var cellProperties = {};
          if (_clmReadonly == undefined) _clmReadonly = [];
          var found = _clmReadonly.find(function (element) {
            return element == prop;
          });
          if (found != undefined) {
            if (self.itemsLF[row] != undefined) {
              cellProperties.className = "myrowreadonly";
              cellProperties.readOnly = true;
              // if (self.$props.items[row].PK != undefined && self.$props.items[row].PK != '')
              //     cellProperties.readOnly = true
              // else
              //     cellProperties.readOnly = false
            }
          }
          //Add by jo 08112020 for set row  color every 4 rows
          if (self.itemsLF[row] != undefined) {
            if (
              self.itemsLF[row].IS_READONLY != null ||
              self.itemsLF[row].IS_READONLY != undefined
            ) {
              if (self.itemsLF[row].IS_READONLY == "T") {
                cellProperties.readOnly = true;
                // if (cellProperties.readOnly == true)
                if (found != undefined)
                  cellProperties.className = "myrowreadonly";
                else cellProperties.className = "myrowdata";
              } else {
                if (cellProperties.readOnly == true)
                  cellProperties.className = "myreadonly";
              }
            }
          }
          //End of Add by jo 08112020 for set row  color every 4 rows
          if (col % 3 === 0) cellProperties.className = "htRight";
          return cellProperties;
        },
        afterValidate: function (isValid, value, row) {
          if (
            row >=
            self.hot.countRows() - self.hot.getSettings().minSpareRows
          ) {
            return true;
          }
        },
        // ,
      };

      // Instantiate
      document.getElementById("divExcelProject").innerHTML = "";
      // eslint-disable-next-line no-undef
      var ht = new Handsontable(
        document.getElementById("divExcelProject"),
        option
      );
      self.hot = ht;
    },
    afterChange(d) {
      console.log(d);
      // if(d != undefined){
      //    var self = this
      //    d.forEach(function(element){
      //         if(element[1].toLowerCase() == 'store_location'){
      //             var aresult = self.filterByClm(self.storeLocationItems,'LOCATION',element[3])
      //             self.itemsLF[element[0]].STORE_DESCRIPTION = aresult[0].DESCRIPTION
      //         }
      //    });
      //    self.hot.loadData(self.itemsLF)
      // }
    },
    genExcelDE() {
      // Handsontable options
      var self = this;
      var AddNew = true;
      var irows = self.itemsDE.length;
      var iclms = self.clmsDE;
      var _clmReadonly = self.clmReadOnly;
      var option = {
        data: self.itemsDE, //output,
        startRows: irows,
        startCols: iclms,
        // minRows: 5,
        minCols: self.clmsDE.length,
        // width: _width,
        maxRows: AddNew == true ? Infinity : irows,
        maxCols: self.clmsDE.length,
        rowHeaders: true,
        colHeaders: self.clmshDE,
        columns: self.clmsDE,
        minSpareRows: AddNew == true ? 5 : 0,
        contextMenu: ["row_above", "row_below", "remove_row"],
        renderAllRows: false,
        colWidths: self.clmswDE,
        beforeKeyDown: function (e) {
          //Add by jo 02012022 for input only numeric on Quanity column (index of 3)
          var clm = this.getSelected()[1];
          if (clm == 2) {
            // var reg = /^\d+$/;
            // var reg = /\d+/g;
            var reg = /^[0-9]*\.?[0-9]*$/;
            if (!reg.test(e.key)) {
              e.preventDefault();
            }
          }
        },
        beforeRemoveRow: function (index, amount) {
          if (self.isValidate == false) {
            if (confirm("Do you want to delete rows?") == false) return false;
          }
          self.isValidate = true;
          var jsonRemove = [];
          var iIndex = index;
          for (var i = 0; i < amount; i++) {
            var element = self.itemsDE[iIndex];
            jsonRemove.push(element);
            iIndex++;
          }
          // self.$emit('removerow', jsonRemove)
        },
        afterChange: function (change) {
          if (change != undefined) {
            change.forEach(function (element) {
              if (element[0] != undefined) {
                self.afterChangeDE(change);
              }
            }, this);
          }
        },
        cells: function (row, col, prop) {
          var cellProperties = {};
          if (_clmReadonly == undefined) _clmReadonly = [];
          var found = _clmReadonly.find(function (element) {
            return element == prop;
          });
          if (found != undefined) {
            if (self.itemsDE[row] != undefined) {
              cellProperties.className = "myrowreadonly";
              cellProperties.readOnly = true;
              // if (self.$props.items[row].PK != undefined && self.$props.items[row].PK != '')
              //     cellProperties.readOnly = true
              // else
              //     cellProperties.readOnly = false
            }
          }
          //Add by jo 08112020 for set row  color every 4 rows
          if (self.itemsDE[row] != undefined) {
            if (
              self.itemsDE[row].IS_READONLY != null ||
              self.itemsDE[row].IS_READONLY != undefined
            ) {
              if (self.itemsDE[row].IS_READONLY == "T") {
                cellProperties.readOnly = true;
                // if (cellProperties.readOnly == true)
                if (found != undefined)
                  cellProperties.className = "myrowreadonly";
                else cellProperties.className = "myrowdata";
              } else {
                if (cellProperties.readOnly == true)
                  cellProperties.className = "myreadonly";
              }
            }
          }
          //End of Add by jo 08112020 for set row  color every 4 rows
          if (col % 3 === 0) cellProperties.className = "htRight";
          return cellProperties;
        },
        afterValidate: function (isValid, value, row) {
          if (
            row >=
            self.hotDE.countRows() - self.hotDE.getSettings().minSpareRows
          ) {
            return true;
          }
        },
        // ,
      };

      // Instantiate
      document.getElementById("divExcelDE").innerHTML = "";
      // eslint-disable-next-line no-undef
      var ht = new Handsontable(document.getElementById("divExcelDE"), option);
      self.hotDE = ht;
    },
    afterChangeDE(d) {
      console.log(d);
      // if(d != undefined){
      //    var self = this
      //    d.forEach(function(element){
      //         if(element[1].toLowerCase() == 'store_location'){
      //             var aresult = self.filterByClm(self.storeLocationItems,'LOCATION',element[3])
      //             self.itemsDE[element[0]].STORE_DESCRIPTION = aresult[0].DESCRIPTION
      //         }
      //    });
      //    self.hot.loadData(self.itemsDE)
      // }
    },
    genExcelSE() {
      // Handsontable options
      var self = this;
      var AddNew = true;
      var irows = self.itemsSE.length;
      var iclms = self.clmsSE;
      var _clmReadonly = self.clmReadOnly;
      var option = {
        data: self.itemsSE, //output,
        startRows: irows,
        startCols: iclms,
        // minRows: 5,
        minCols: self.clmsSE.length,
        // width: _width,
        maxRows: AddNew == true ? Infinity : irows,
        maxCols: self.clmsSE.length,
        rowHeaders: true,
        colHeaders: self.clmshSE,
        columns: self.clmsSE,
        minSpareRows: AddNew == true ? 5 : 0,
        contextMenu: ["row_above", "row_below", "remove_row"],
        renderAllRows: false,
        colWidths: self.clmswSE,
        beforeKeyDown: function (e) {
          //Add by jo 02012022 for input only numeric on Quanity column (index of 3)
          var clm = this.getSelected()[1];
          if (clm == 2) {
            // var reg = /^\d+$/;
            // var reg = /\d+/g;
            var reg = /^[0-9]*\.?[0-9]*$/;
            if (!reg.test(e.key)) {
              e.preventDefault();
            }
          }
        },
        beforeRemoveRow: function (index, amount) {
          if (self.isValidate == false) {
            if (confirm("Do you want to delete rows?") == false) return false;
          }
          self.isValidate = true;
          var jsonRemove = [];
          var iIndex = index;
          for (var i = 0; i < amount; i++) {
            var element = self.itemsSE[iIndex];
            jsonRemove.push(element);
            iIndex++;
          }
          // self.$emit('removerow', jsonRemove)
        },
        afterChange: function (change) {
          if (change != undefined) {
            change.forEach(function (element) {
              if (element[0] != undefined) {
                self.afterChangeSE(change);
              }
            }, this);
          }
        },
        cells: function (row, col, prop) {
          var cellProperties = {};
          if (_clmReadonly == undefined) _clmReadonly = [];
          var found = _clmReadonly.find(function (element) {
            return element == prop;
          });
          if (found != undefined) {
            if (self.itemsSE[row] != undefined) {
              cellProperties.className = "myrowreadonly";
              cellProperties.readOnly = true;
              // if (self.$props.items[row].PK != undefined && self.$props.items[row].PK != '')
              //     cellProperties.readOnly = true
              // else
              //     cellProperties.readOnly = false
            }
          }
          //Add by jo 08112020 for set row  color every 4 rows
          if (self.itemsSE[row] != undefined) {
            if (
              self.itemsSE[row].IS_READONLY != null ||
              self.itemsSE[row].IS_READONLY != undefined
            ) {
              if (self.itemsSE[row].IS_READONLY == "T") {
                cellProperties.readOnly = true;
                // if (cellProperties.readOnly == true)
                if (found != undefined)
                  cellProperties.className = "myrowreadonly";
                else cellProperties.className = "myrowdata";
              } else {
                if (cellProperties.readOnly == true)
                  cellProperties.className = "myreadonly";
              }
            }
          }
          //End of Add by jo 08112020 for set row  color every 4 rows
          if (col % 3 === 0) cellProperties.className = "htRight";
          return cellProperties;
        },
        afterValidate: function (isValid, value, row) {
          if (
            row >=
            self.hotSE.countRows() - self.hotSE.getSettings().minSpareRows
          ) {
            return true;
          }
        },
        // ,
      };

      // Instantiate
      document.getElementById("divExcelSE").innerHTML = "";
      // eslint-disable-next-line no-undef
      var ht = new Handsontable(document.getElementById("divExcelSE"), option);
      self.hotSE = ht;
    },
    afterChangeSE(d) {
      console.log(d);
      // if(d != undefined){
      //    var self = this
      //    d.forEach(function(element){
      //         if(element[1].toLowerCase() == 'store_location'){
      //             var aresult = self.filterByClm(self.storeLocationItems,'LOCATION',element[3])
      //             self.itemsSE[element[0]].STORE_SESCRIPTION = aresult[0].SESCRIPTION
      //         }
      //    });
      //    self.hot.loadData(self.itemsSE)
      // }
    },
    getCurrentDate() {
      let currentDate = new Date();
      let cDay = currentDate.getDate();
      let cMonth = currentDate.getMonth() + 1;
      let cYear = currentDate.getFullYear();
      return cDay + "/" + cMonth + "/" + cYear;
    },
    submitProject(){
      var self =this;
      var aSave = [];
      var bEmptyData =false
      self.itemsLF.forEach(item => {
       if(item.WbsNumber == null && item.ProjectName == null && item.WbsCode == null &&
       item.Consultant == null && item.EffFrom == null && item.EffTo == null 
       && item.MethodHcfs == null && item.MethodLoi == null && item.ServiceDescription == null) 
        bEmptyData = true;
        else{
          aSave.push({
            WbsNumber: item.WbsNumber,
            ProjectName: item.ProjectName,
            WbsCode: item.WbsCode,
            Consultant: item.Consultant,
            EffFrom: item.EffFrom,
            EffTo: item.EffTo,
            MethodHcfs: item.MethodHcfs,
            MethodLoi: item.MethodLoi,
            ServiceDescription: item.ServiceDescription
          })
        }
      });
      aSave.forEach(element => {
        element.EffFrom = self.dateToIso(element.EffFrom)
        element.EffTo = self.dateToIso(element.EffTo)
       ProjectService.getData(element.WbsNumber).then(response => {
         if(response.ok)
          return response.json()
          else if(response.status === 404)
          return Promise.reject('error 404')
          else
          return Promise.reject('some other error:' + response.status)
       }) 
       .then(data => {
         console.log('Call put to update')
         console.log(data)
         ProjectService.updateProjectData(element.WbsNumber,element).then(response => {
           console.log(response.data)
         })
       })
       .catch(error => {
        if(error === 'error 404'){
          ProjectService.addProjectData(element).then(response =>{
            console.log(response.data)
          })
        }
       } )
      });
      // ProjectService.addProjectData(aSave).then(response =>{
      //   console.log(response.data)
      // })
    },
  },
  computed:{
    cProjectDist(){
      var self = this
      return self.itemsLF.map(item => item.WbsNumber)
      .filter((value, index, self) => self.indexOf(value) === index)
      .filter( a => a != null)
    }
  }
};
</script>
<style scoped>
.container {
  margin-top: 60px;
}
</style>