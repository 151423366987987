

import axios from "axios";
import apiService from '../services/api.service'
const API_URL = apiService.getApiUrl();
class ServiceService{
    getData(projectID){
        return axios.get(API_URL + "service/" + projectID,{
            projectID: projectID
        })
    }
    saveService(data){
        return axios.post(API_URL + "saveService",{
            services: data,
        });
    }
    removeData(data){
        return axios.post(API_URL + 'servicesRemove',{
            services: data,
        })
    }
}
export default new ServiceService();