// eslint-disable-next-line no-unused-vars
import axios from "axios";
// const API_URL = "http://localhost:7111/";
// const API_URL = "https://www.psolutionplus.com/";
import ApiService from '../services/api.service';
const API_URL = ApiService.getApiUrl();
class EmployeeService{
    getData(){
        return fetch(API_URL + 'employees');
    }
    saveEmployee(data){
        console.log(data)
        return axios.post(API_URL + 'saveEmployee',{
            employees: data,
        });
    }
    removeData(data){
        return axios.post(API_URL + 'employeesRemove',{
            employees: data,
        })
    }
}
export default new EmployeeService();