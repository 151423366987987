import axios from 'axios';
import authHeader from './auth-header';
// const API_URL = 'http://localhost:5126/';
// const API_URL = "http://localhost:7111/";
// const API_URL = "https://www.psolutionplus.com/";
import ApiService from '../services/api.service';
const API_URL = ApiService.getApiUrl();
class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'doaction');
    // return axios.get(API_URL + 'all');
  }
  getUserBoard() {
    return axios.get(API_URL + 'doaction', { headers: authHeader() });
    // return axios.get(API_URL + 'user', { headers: authHeader() });
  }
  getModeratorBoard() {
    return axios.get(API_URL + 'doaction', { headers: authHeader() });
    // return axios.get(API_URL + 'mod', { headers: authHeader() });
  }
  getAdminBoard() {
    return axios.get(API_URL + 'doaction', { headers: authHeader() });
    // return axios.get(API_URL + 'admin', { headers: authHeader() });
  }
  getUsers(){
    return axios.get(API_URL + "users");
  }

  saveUser(data){
      console.log(data)
      return axios.post(API_URL + 'saveUser',{
          users: data,
      });
  }
  removeData(data){
      return axios.post(API_URL + 'usersRemove',{
          users: data,
      })
  }
}
export default new UserService();