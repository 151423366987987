<template>
    <div class="container">
        <header class="jumbotron">
            <!-- <h3>{{content}}</h3> -->
        </header>
    </div>
</template>
<script>
import UserService from "../services/user.service";
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Home",
    data(){
        return {
            content: "",
        }
    },
    computed: {
      currentUser() {
        // console.log('-----------Profile----------------')
        // console.log(this.$store.state.auth.user)
        return this.$store.state.auth.user;
      }
    },
    mounted(){
        if (!this.currentUser) {
          this.$router.push('/login');
        }
        UserService.getPublicContent().then((response) => {
                response.data;
                console.log(response.data)
            },
            (error) => {
                console.log('---Error on call getpubliccontent--------')
                this.content = (error.response && error.response.data && error.response.data.message) ||
                error.message || error.toString();
            });
    },
}
</script>