<template>
    <div class="container-fluid gap-3">
        <div class="row mb-3">
           <div class="col-1"></div> 
            <h3 class="col-10 text-lg-left text-primary">How to use?</h3>
        </div>
        <div class="row mb-3">
           <div class="col-3"></div> 
           <div class="col-6">
            <video controls>
                <source :src="helpSrc" type="video/mp4"/>
                Your browser does not support HTML video.
            </video>
           </div>
            <br/>
        </div>
        <div class="row mb-3">
           <div class="col-1"></div> 
            <h3 class="col-10 text-lg-left text-primary" style="padding-top:60px;">How to create shotcut on iPhone?</h3>
        </div>
        <div class="row mb-3">
           <div class="col-3"></div> 
           <div class="col-6">
                <video controls>
                    <source :src="shotcutSrc" type="video/mp4"/>
                    Your browser does not support HTML video.
                </video>
           </div>
        </div>
        <div class="row mb-3">
           <div class="col-1"></div> 
            <h3 class="col-10 text-lg-left text-primary" style="padding-top:60px;">How to create shotcut on Android?</h3>
        </div>
        <div class="row mb-3">
           <div class="col-3"></div> 
           <div class="col-6">
                <video controls>
                    <source :src="shotcutAndroidSrc" type="video/mp4"/>
                    Your browser does not support HTML video.
                </video>
           </div>
        </div>
    </div>
</template>
<script>
import {ref} from 'vue'
export default {
    name: 'HelpForm',
    setup() {
       const helpSrc = ref('/video/help.mov') 
       const shotcutSrc = ref('/video/shotcut_video.mov')
       const shotcutAndroidSrc = ref('/video/shotcut_video_android.mov')
       return{
           helpSrc,
           shotcutSrc,
           shotcutAndroidSrc
       }
    },
}
</script>

<style scoped>
.container-fluid{
    margin-top:60px;
}
</style>