
import axios from 'axios';
// const API_URL = 'http://localhost:5126/';
// const API_URL = "http://localhost:7111/";
// const API_URL = "https://www.psolutionplus.com/";
import ApiService from '../services/api.service';
const API_URL = ApiService.getApiUrl();
class BankService {
  getBanks(){
    return axios.get(API_URL + "banks");
  }

  saveBank(data){
      return axios.post(API_URL + 'saveBank',{
          banks: data,
      });
  }
  removeData(data){
      return axios.post(API_URL + 'banksRemove',{
          banks: data,
      })
  }
}
export default new BankService();